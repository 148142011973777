.template-contact {
  @media (min-width: 1200px) {
    #profile-informations .container {
      width: 50%;
      margin: 20px;
    }
  }
  .dk-actions {
    display: none;
    position: absolute;
    top: 50px;
    left: 380px;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    width: calc(100% - 450px);
    @media (min-width: 1200px) {
      display: flex;
    }
    button {
      margin: 10px 0;
      background: transparent;
      display: flex;
      align-items: center;
      span {
        margin: 0 10px;
        color: $annuaire_desktop_raccourcis_icones;
        font-size: 12px;
      }
      &:not(.favorite) svg {
        fill: $annuaire_desktop_raccourcis_icones;
      }
      &.favorite {
        svg {
          stroke: $annuaire_desktop_raccourcis_icones;
          fill: transparent;
        }
        &.active {
          svg {
            fill: $annuaire_desktop_raccourcis_icones;
          }
        }
      }
    }
  }
  .loader-container {
    position: relative;
    height: 50vh;
    z-index: 1;
  }
}

