#short-links {
  .grid50 {
    padding: 0 10px;

    span {
      font-size: 20px;
      font-weight: 800;
      padding-bottom: 10px;
      position: relative;
      display: block;
    }
    > span {
      color: $shortlinks_lien_actif;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: $shortlinks_lien_actif;
      }
    }
    button {
      background: none;
      span {
        color: $shortlinks_lien_inactif;
      }
    }
  }
}

.badge-new-post {
  width: 28px;
  height: 28px;
  background: $badge_nouveaute_fond;
  box-shadow: 0 0 5px 0 rgba(73, 73, 73, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-10px, -50%);
  svg {
    fill: $badge_nouveaute_couleur;
    height: 20px;
  }
}

.template-home {
  .post {
    .actions-post {
      .picto {
        width: calc(50% - 22.5px);
      }
    }
  }
  &.template-posts-mega-menu {
    #header .navbar-top {
      .toggle {
        display: none;
      }
    }
  }
}

#posts {
  padding: 20px 0;
  visibility: visible;
  .post {
    width: 50%;
    position: relative;
    .admin-bar {
      top: 10px;
      right: 10px;
      @media (min-width: 1200px) {
        top: 20px;
        right: 20px;
      }
    }
  }
  @media (min-width: 1200px) {
    .post {
      width: calc(100% / 3);
      max-width: calc(100% / 3);
      padding: 20px;
    }
  }
}

#important-post {
  padding-top: 20px;
  .post {
    position: relative;
    .admin-bar {
      right: 10px;
      top: 10px;
    }
    .link{
      display: flex;
    }
    .content {
      display: flex;
      flex-direction: column;
      
      order: 1;
      background: $accueil_article_fleche_fond;
      * {
        color: $accueil_article_important_texte_couleur;
      }
      .important {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 5px;
      }
      .extract {
        padding-bottom: 10px;
        font-size: 12px;
      }

      .actions-post {
        justify-content: flex-start;
        position: relative;
        .fake-link {
          background: $accueil_article_fleche_couleur;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: $input_radius * 2;
          position: absolute;
          bottom: 0;
          right: 0;
          svg {
            fill: $accueil_article_fleche_fond;
          }
        }

        .fake-link-boutique {
          background: $accueil_article_fleche_couleur;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: $input_radius * 2;
          position: absolute;
          bottom: 0;
          right: 0;
          svg {
            fill: $accueil_article_fleche_fond;
          }
        }
        
        
        .picto {
          min-width: 50px;
          width: auto;
          & + .picto::before {
            background: $accueil_article_important_texte_couleur;
          }
          svg {
            stroke: $accueil_article_important_texte_couleur;
          }
        }
      }
    }
    @media (min-width: 700px) {
      .link {
        flex-direction: row;
        align-items: stretch;
       
        .visuel {
          img {
            // max-height: none;
          }
        }
        .content {
          position: relative;
          padding: 10px;
          .actions-post {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
            padding-left: 8px;
          }
        }
      }
    }
  }
}

.invisible {
  // visibility: hidden;
  > .post {
    opacity: 0;
  }
}

.post {
  padding: 10px;
  opacity: 1;
  transition: opacity 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);

  .badge-new-post {
    top: 0;
    transform: none;
    left: 0;
    @media (min-width: 1200px) {
      top: 10px;
      left: 5px;
    }
  }
  .actions-post {
    display: flex;
    align-items: center;
    height: 32px;
    margin-left: -8px;
    margin-right: -8px;
    .picto {
      font-size: 13px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      + .picto {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 1px;
          height: 18px;
          background: $article_likes_commentaires_couleur;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      svg {
        stroke: $article_likes_commentaires_couleur;
        fill: none;
        margin-right: 5px;
        width: 15px;
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      &.active {
        svg {
          fill: $article_likes_commentaires_couleur;
        }
      }
    }
  }

  .link {
    overflow: hidden;
    background: $accueil_article_fond;
    border-radius: $input_radius * 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    position: relative;
    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
    .content {
      padding: 10px 8px 0;
      width: 100%;
      * {
        color: $accueil_article_texte_couleur;
      }
      .social-icon {
        height: 25px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        padding-left: 10px;
        margin-top: -10px;
        margin-left: -8px;
        margin-right: -8px;
        &.facebook {
          background: #2e4a85;
        }
        &.linkedin {
          background: #2b89c9;
        }
        &.instagram{
          background: #f09433; 
          background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
          background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
          background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 ); 
        }
        svg {
          fill: #fff;
          height: 15px;
          width: 15px;
        }
      }
      .titre {
        line-height: 15px;
        letter-spacing: 0.24px;
        font-size: 12px;
        font-weight: 800;
        padding-bottom: 15px;
        padding-right: 20px;
      }
      .date {
        font-size: 11px;
        padding-bottom: 12px;
      }
      .social-tags {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 8px;
        margin-right: -4px;
        margin-left: -4px;
        span {
          font-size: 11px;
          color: $liste_hashtags_fond;
          padding: 2px 4px;
          // margin: 2px 3px;
        }
      }
      .actions-post {
        justify-content: flex-end;
        flex-wrap: wrap;
        @media (min-width: 700px) {
          flex-wrap: nowrap;
        }
        .fake-link {
          width: 45px;
          height: 100%;
          background: $accueil_article_fleche_fond;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: $input_radius * 2;
          margin-right: 0;
          margin-left: auto;
          svg {
            fill: $accueil_article_fleche_couleur;
          }
        }
        .fake-link-boutique{
   
            width: 45px;
            height: 100%;
            background: $accueil_article_fleche_fond;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: $input_radius * 2;
            margin-right: 0;
            margin-left: auto;
            svg {
              fill: $accueil_boutique_article_fleche_couleur;
            }
        }
      }
      .moderation {
        margin-left: -8px;
        margin-right: -8px;
        width: calc(100% + 16px);
      }
    }
    .gallery {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      svg {
        width: 20px;
        height: 20px;
        fill: #fff;
      }
    }
    .visuel {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        max-height: 300px;
        object-fit: cover;
      }
    }

    .visuel-video{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      video{
        width:100%;
        height:100%;
        max-height: 300px;
        object-fit: cover;
      }
    }

  }
  @media (min-width: 1200px) {
    flex: auto;
  }
}

#searchbar {
  .closed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    display: flex;
    justify-content: space-between;
    z-index: 11;
    @media (min-width: 700px) and (max-width: 1200px) {
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
    }
    .title {
      width: calc(100% - 160px);
      display: flex;
      align-items: center;
      text-transform: uppercase;
      justify-content: center;
      text-align: center;
      background: $searchbar_fond;
      font-size: 13px;
      letter-spacing: 1.4px;
      color: $searchbar_inactive_couleur;
      svg {
        margin-right: 10px;
        width: 12px;
        height: 12px;
        fill: $searchbar_inactive_couleur;
      }
    }
    // button {
    //   background: transparent;
    //   margin-left: 10px;
    //   svg {
    //     fill: $searchbar_inactive_couleur;

    //     margin-right: 0 !important;
    //   }
    // }
    .svg {
      transform: rotateX(180deg);
      &.right {
        transform: rotateX(180deg) rotateY(180deg);
      }
    }
  }

  .opened {
    position: fixed;
    z-index: 13;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background: $searchbar_fond;
    padding: 20px 30px;
    box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.19), 0 -2px 6px rgba(0, 0, 0, 0.23);
    transform: translateY(100%);
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    @media (min-width: 700px) and (max-width: 1200px) {
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
    }
    > span {
      display: none;
    }

    &.active {
      transform: translateY(0);
    }
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      height: 5px;
      width: 34px;
      background: $searchbar_couleur_simulateur_micro;
      border-radius: 2.5px;
    }
    button[type='submit'] {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 8px;
      margin: auto;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      svg {
        fill: $searchbar_active_couleur;
      }
    }
  }
  input {
    padding-right: 40px;
  }
  #reset {
    background: none;
    padding-left: 5px;
    font-size: 13px;
    color: $searchbar_annuler_couleur;
  }
  @media (min-width: 1200px) {
    .closed {
      width: 260px;
      bottom: auto;
      top: 0;
      left: 30px;
      z-index: 1001;
      height: 46px;
      justify-content: center;
      cursor: pointer;
      .svg {
        transform: translateY(-4px);
        &.right {
          transform: translateY(-4px) rotateY(180deg);
        }
      }
      .title {
        width: 100%;
      }
    }
    .opened {
      height: calc(100vh - 115px);
      z-index: 2000;
      border-radius: 0;
      flex-direction: column;

      > span {
        padding-bottom: 40px;
        color: $searchbar_annuler_couleur;
        font-size: 20px;
        font-weight: 800;
        // text-transform: uppercase;
        font-style: italic;
        display: block;
      }
      form {
        width: 500px;
      }
    }
  }
}

.dk-grid {
  @media (min-width: 1200px) {
    width: 870px;
    max-width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
    transform: translateX(calc(320px / 2)) !important;
  }
}

.delete-post {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.moderation {
  background: $accueil_article_fleche_fond !important;
  color: $accueil_article_fleche_couleur !important;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 100%;
}

.badge {
  color: $badge_couleur !important;
  font-size: 10px;
  margin: 0 0 8px 0;
  padding: 5px;
  background: $badge_fond !important;
  width: 130px;
  text-align: center;
  border-radius: $input_radius;
  max-width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.admin-bar {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  border-bottom-left-radius: $input_radius * 2;
  border-top-right-radius: $input_radius * 2;
  right: 0;
  top: 0;
  z-index: 10;
  > span {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 25px;
    height: 25px;
    background: $accueil_article_bar_admin_icone_fond;
    border-bottom-left-radius: $input_radius * 2;
    border-top-right-radius: $input_radius * 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    overflow: hidden;
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }
    svg {
      fill: $accueil_article_bar_admin_icone_couleur;
      width: 14px;
      height: 14px;
      pointer-events: none;
    }
    .inactive {
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
    }
    .active {
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: scale(0);
    }
  }
  div {
    position: relative;
    z-index: 2;
    background: $accueil_article_fond;
    border-radius: $input_radius * 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 25px;
    max-height: 25px;
    padding-bottom: 5px;
    overflow: auto;
    a,
    button {
      &:not(:first-child) {
        border-top: 1px solid #333333;
      }
      color: #000;
      padding: 4px 15px;
      opacity: 0;
      width: 100%;
      font-size: 13px;
      background: $accueil_article_fond;
    }
  }
  @media (min-width: 1200px) {
    &:not(.active):hover {
      span {
        box-shadow: 0 0 12px 0 rgba(43, 43, 43, 0.5);
      }
    }
  }
  &.active {
    > span {
      .inactive {
        transform: scale(0);
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      .active {
        transform: scale(1);
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
      }
    }
    div {
      max-width: 100%;
      max-height: 100%;
      padding-top: 30px;
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      box-shadow: 0 0 12px 0 rgba(43, 43, 43, 0.5);

      a,
      button {
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
        opacity: 1;
      }
    }
  }
}

#filters {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;
  > div {
    margin: 10px;
    border-radius: 23.5px;
    border: solid 1px #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 15px;
    width: 180px;
    cursor: pointer;
    position: relative;
    max-width: calc(50vw - 20px) !important;
    @media (min-width: 1200px) {
      margin: 20px;
    }
    > svg {
      transform: rotate(90deg);
      width: 16px;
      pointer-events: none;
    }
    > span {
      pointer-events: none;
      padding-right: 10px;
    }
    &.selected {
      border-color: $sondage_filtre_actif_fond;
      background: $sondage_filtre_actif_fond;
      > span {
        color: #fff;
      }
      > svg {
        fill: #fff;
      }
    }
    &.inactive {
      border-color: #c4c4c4;
      > span {
        color: #c4c4c4;
      }
      > svg {
        fill: #c4c4c4;
      }
    }
  }
  .groups {
    position: relative;
    .options {
      z-index: 2;
      background: #fff;
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      box-shadow: 0 0 12px 0 rgba(43, 43, 43, 0.5);
      border-radius: $input_radius;
      width: 205px;
      display: none;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px;
      > span {
        color: $sondage_filtre_titre_couleur;
        font-weight: 800;
        padding-bottom: 10px;
        font-size: 16px;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 2px 0;
        color: #000;
        font-weight: normal;
        cursor: pointer;
        text-align: left;
        span {
          border-radius: 50%;
          width: 16px;
          height: 16px;
          margin-right: 10px;
          border: 1px solid #000;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 8px;
            height: 8px;
            display: none;
          }
        }
        &.active {
          font-weight: bold;
          color: $sondage_filtre_choix_couleur;
          span {
            background: $sondage_filtre_choix_couleur;
            border-color: $sondage_filtre_choix_couleur;
            svg {
              fill: #fff;
              display: block;
            }
          }
        }
      }
    }
    &.active {
      .options {
        display: flex;
      }
    }
  }
}

.no-posts {
  text-align: center;
  padding: 40px 20px 20px;
  font-weight: bold;
  font-size: 14px;
  max-width: 100%;
  flex: 1;
  color: $aucun_contenu_couleur;

  .term {
    font-style: italic;
  }

}
