#table {
  .line {
    height: 60px;
    border-radius: $input_radius;
    color: #4a4a4a;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
      height: 55px;
    }

    div:not(.button) {
      flex-basis: 100%;
      padding: 2px 15px;

      @media (max-width: 1200px) {
        max-width: 35rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 2px 0 2px 10px;
        text-align: left;
      }

      >svg {
        fill: #4a4a4a;
        height: 14px;
        width: 14px;
        margin-right: 2px;
      }
    }

    div:not(.status) {
      font-weight: 600;
    }

    .status {
      font-weight: bolder;
      font-style: italic;
    }

    .button {
      padding: 2px 0 2px 12px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        background: #002e5a;
        border-radius: 50%;
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(50%);

        svg {
          fill: #fff;
        }
      }

      @media (max-width: 1200px) {
        transform: translateX(-30%);
      }
    }

    span {
      font-weight: bold;
      font-style: italic;
    }

    &:nth-child(even) {
      background: #fff;
    }

    &:nth-child(odd) {
      background: #f7f7f7;
    }

    &:hover {
      background: rgba($color: #002e5a, $alpha: 0.2);
    }
  }

  .legend {
    height: 48px;
    border-radius: $input_radius;
    color: #a0a0a0;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;

    &.fixed {
      position: fixed;
      z-index: 10;
      top: 170px;
      background: #002e5a;
      color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      .button span {
        background: #002e5a;
      }
    }

    div:not(.button) {
      flex-basis: 100%;
      padding: 2px 12px;
      text-align: center;
    }

    .button {
      padding: 2px 0 2px 12px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        background: #f7f7f7;
        border-radius: 50%;
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .loader-container {
    position: relative;
    height: 100px;
  }
}

#table-sorts {
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  div {
    padding: 8px;

    button {
      width: 100%;
      padding: 12px 0px;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #4a4a4a;
      background: #fff;
      font-weight: bold;
      border-radius: $input_radius;
      font-size: 14px;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      @media (max-width: 1200px) {
        padding: 0px 0px;
      }

      span {
        width: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 4px 0;

        svg {
          fill: #4a4a4a;
          width: 6px;
          transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

          &:first-child {
            transform: rotate(-90deg);
          }

          &:last-child {
            transform: rotate(90deg);
          }
        }
      }

      .labelBox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
          font-size: 8px;
          font-weight: 700;
        }
      }

      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      }

      &.asc,
      &.desc {
        color: #fff;
        background: #002e5a;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      }

      &.asc {
        span {
          svg:first-child {
            fill: #fff;
          }
        }
      }

      & .desc {
        span {
          svg:last-child {
            fill: #fff;
          }
        }
      }
    }
  }
}

#pgl-home-header {
  .center {
    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >* {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      >.filter {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 0;
      }
    }

    .hidden {
      display: none !important;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >* {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    >p {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: #002e5a;
      border-bottom: 1px solid #707070;
      padding-bottom: 10px;
    }

    .add-prospect {
    
      color: #00b1eb;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      span {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00b1eb;
        margin-bottom: 5px;
        height: 32px;
        width: 32px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

        svg {
          fill: #fff;
          transform: rotate(45deg);
          width: 15px;
          height: 15px;
        }
      }

      &:hover {
        span {
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
      }
    }

    .export-selected-leads {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
        fill: #fff;
      }
    }

    .export-all-leads {
      text-decoration: underline;
      font-size: 12px;
      font-weight: 500;
      color: #4a4a4a;
    }
  }
}

#table-title {
  margin-top: 20px;
  pointer-events: none;

  h1 {
    font-weight: bold;
    color: #fff;
    text-align: center;
    border-radius: $input_radius;
  }

  .contextTitle {
    background: linear-gradient(to bottom, #004f9f, #002e5a);
    padding: 16px;
    font-size: 20px;
  }

  .title {
    color: #a0a0a0;
    font-size: 14px;
  }
}