.template-leads {
  #table-filters {
    .filter {
      width: 580px;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 10px;
      z-index: 2;
      .label,
      .input {
        width: 250px;
        text-align: left;
        font-size: 14px;

        height: 38px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: $input_radius;
      }
      .label {
        background: #002e5a;
        color: #fff;
        pointer-events: none;
        padding-left: 16px;
        padding-right: 16px;
      }
      .input {
        background: #fff;
        color: #4a4a4a;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        position: relative;
        z-index: 1;
        .content {
          position: relative;
          display: flex;
          overflow: auto;
          align-items: center;
          height: 100%;
          z-index: 1;
          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        &:hover {
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }
        input {
          border: none;
          padding-left: 16px;
          padding-right: 16px;
        }
        span {
          background: #002e5a;
          color: #fff;
          height: 24px;
          margin-left: 5px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 14px;
          padding: 5px;
          border-radius: $input_radius;
          position: relative;
          flex: none;
          button {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(40%, -40%);
            border-radius: 50%;
            height: 18px;
            width: 18px;
            background: #c4c4c4;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              height: 8px;
              width: 8px;
              fill: #333333;
            }
          }
        }
        .suggestions {
          position: absolute;
          background: #e2e2e2;
          top: 100%;
          left: 0;
          width: 100%;
          font-weight: 500;
          color: #a0a0a0;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding-top: 10px;
          margin-top: -5px;
          padding-bottom: 4px;
          z-index: -1;
          border-bottom-left-radius: $input_radius;
          border-bottom-right-radius: $input_radius;
          button {
            padding-right: 16px;
            padding-left: 16px;
            padding-top: 4px;
            padding-bottom: 4px;
            background: none;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            width: 100%;
            text-align: left;
          }
        }
      }
      .delete {
        button {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #a0a0a0;
          border-radius: 50%;
          line-height: 0;
          color: #fff;
          transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          &:hover {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          }
        }
      }
    }
  }
  .filter-selector {
    position: relative;
    display: flex;
    cursor: pointer;
    > span {
      height: 38px;
      border-top-left-radius: $input-radius;
      border-bottom-left-radius: $input-radius;
      font-size: 20px;
      font-weight: 600;
      color: #002e5a;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.23), 0 1px 2px rgba(0, 0, 0, 0.24);
      // z-index: 3;
    }
    > button {
      background: #002e5a;
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: $input-radius;
      border-bottom-right-radius: $input-radius;
    }
    .options {
      position: absolute;
      // z-index: 2;
      // top: 30px;
      padding: 10px 0;
      top: 0;
      display: none;
      width: 100%;
      background: #002e5a;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      width: calc(100% - 38px);
      border-top-left-radius: $input-radius;
      border-bottom-right-radius: $input-radius;
      border-bottom-left-radius: $input-radius;
      max-height: none;
      overflow: auto;
      button {
        height: 24px;
        width: 100%;
        background-color: #002e5a;
        font-size: 14px;
        color: #fff;

        &:hover {
          background-color: #ffffff69;
        }
      }
    }
    &.active {
      > button {
        svg {
          transform: rotate(-90deg);
        }
      }
      .options {
        display: block;
      }
    }
  }
}
