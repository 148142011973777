.template-shop {
  @media (min-width: 1200px) {
    #header .navbar-top .tab {
      left: 0;
      width: 100%;
    }
    .dk-grid {
      transform: none !important;
    }
  }
  .contacts-filters {
    button[data-typologie='formation'] {
      display: none;
    }
  }
  // #filters {
  //   justify-content: flex-start;
  //   > div {
  //     &.selected {
  //       border-color: $boutique_filtres_couleur_principale;
  //       background: $boutique_filtres_couleur_principale;
  //     }

  //     .options {
  //       > span {
  //         color: $boutique_filtres_couleur_principale;
  //       }
  //       .reset {
  //         border-radius: 50%;
  //         width: 20px;
  //         height: 20px;
  //         background: $boutique_filtres_couleur_principale;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         position: absolute;
  //         top: 9px;
  //         right: 10px;
  //         svg {
  //           fill: #fff;
  //           width: 9px;
  //           height: 9px;
  //         }
  //       }
  //       .active {
  //         color: $boutique_filtres_couleur_principale;
  //         .check {
  //           border-color: $boutique_filtres_couleur_secondaire;
  //           background: $boutique_filtres_couleur_secondaire;
  //         }
  //       }
  //     }
  //   }
  // }
  #posts {
    padding: 0;
    position: relative;
    > .error {
      width: 100%;
      max-width: 100%;
      flex: 1;
      text-align: center;
      padding: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #e20714;
    }
    .post {
      .social-tags {
        span {
          color: $boutique_preview_produit_couleur_principale;
        }
      }

      .tarif {
        color: $boutique_preview_produit_couleur_principale;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        line-height: initial;
        @media (min-width: 1200px) {
          font-size: 26px;
        }
        span {
          color: $boutique_preview_produit_couleur_principale;
          font-size: 12px;
          margin-left: 2px;
          display: inline-block;
          // display: none;
          // @media (min-width: 1200px) {
          // }
        }
        &.raye {
          font-size: 16px;
          color: $boutique_preview_produit_couleur_prix_raye;
          text-decoration: line-through;
          span {
            color: $boutique_preview_produit_couleur_prix_raye;
          }
          @media (min-width: 1200px) {
            font-size: 18px;
          }
        }
      }
      .fake-link {
        background: $boutique_preview_produit_couleur_secondaire;
        height: 32px;
      }
      .fake-link-boutique {
        background: $boutique_preview_produit_couleur_secondaire;
        height: 32px;
      }
      .actions-post {
        justify-content: space-between;
        height: auto;
        align-items: flex-end;
        .picto {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          min-height: 32px;
          max-width: calc(100% - 45px);
          .post-alert {
            background: $boutique_preview_produit_couleur_alerte;
            margin: 5px 0;
            padding: 5px;

            span {
              color: #fff;
              line-height: initial;
              &:nth-child(1) {
                font-size: 20px;
                font-weight: bold;
                padding-right: 5px;
                @media (min-width: 1200px) {
                  font-size: 18px;
                }
              }
              &:nth-child(2) {
                font-size: 12px;
              }
            }
            &.alert-promo,
            &.alert-stock,
            &.alert-stock-precommande {
              display: flex;
              flex-direction: column;
            }
            &.full {
              background: $boutique_preview_produit_couleur_alerte_indisponible;
              span {
                font-size: 12px;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}
