#calendar {
  display: flex;
  flex-direction: column;
  // margin: 0 -10px;
  width: 100%;
  .header {
    overflow: hidden;
    // width: 100vw;
    .months {
      display: flex;
      align-items: flex-end;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 20px 0;
      @media (min-width: $desktop_width) {
        width: calc(100% - 60px);
        margin: 0 30px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .old {
        display: flex;
      }
      .month {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 15px;
        font-size: 18px;
        font-weight: 800;
        color: $calendrier_mois_couleur_inactif;
        background: none;
        &.current {
          color: $calendrier_mois_couleur_actif;
        }
        .year {
          font-size: 11px;
          font-weight: bold;
          line-height: 21px;
        }
      }
    }
  }
  .days {
    padding: 0 30px;
    font-weight: 800;
    font-size: 10px;
    color: $calendrier_jour;
    display: flex;
    align-items: center;
    justify-content: space-around;
    > span {
      text-align: center;
      width: calc(100% / 7);
      padding-bottom: 5px;
      border-bottom: 1px solid $calendrier_jour;
    }
  }

  .dates {
    padding: 5px 30px;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    color: $calendrier_date;
    flex-wrap: wrap;

    .date {
      padding: 7px 0;
      width: calc(100% / 7);
      height: 100%;
      text-align: center;
      cursor: default;
      &.current {
        font-weight: 800;
      }
      &.active {
        color: $calendrier_date_active_couleur;
        position: relative;
        z-index: 1;
        cursor: pointer;
        &::after {
          border-radius: 50%;
          background: $calendrier_date_active_fond;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          content: '';
          margin: auto;
          width: 30px;
          height: 30px;
          z-index: -1;
        }
      }
    }
    .empty {
      display: flex;
      .date {
        width: calc(calc(100vw - 80px) / 7);
        @media (min-width: 1200px) {
          width: calc(calc(574px - 60px) / 7);
        }
      }
    }
  }
}

.template-calendar {
  background: $calendrier_fond_page;
  padding-top: 0;
  &::after,
  &::before {
    opacity: 0.2;
  }
  #header {
    .navbar-top {
      
    }
  }

  #events {
    > :not(:first-child) {
      .month {
        margin-top: 40px;
      }
    }
    .month {
      background: $liste_evenements_mois_fond;
      height: 21px;
      display: flex;
      align-items: center;
      padding: 0 30px;
      font-size: 11px;
      color: $liste_evenements_mois_couleur;
      font-weight: 600;
      text-transform: uppercase;
    }
    .event {
      margin: 20px 10px;
      border-radius: $input_radius * 2;
      background: $liste_evenements_evenement_fond;
      box-shadow: 0 10px 10px 0 rgba(177, 177, 177, 0.5);
      // overflow: hidden;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        pointer-events: none;
        z-index: 11;
        border-radius: $input-radius * 2;
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        @media (min-width: 1200px) {
          // top: 15px;
          // bottom: 15px;
          // left: 15px;
          // right: 15px;
          // width: calc(100% - 30px);
          // height: calc(100% - 30px);
        }
      }
      .admin-bar {
        > span {
          height: 40px;
          border-bottom-left-radius: 0;
        }
        &.active {
          div {
            padding-top: 40px;
          }
        }
      }
      .date {
        background: $liste_evenements_evenement_date_fond;
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        color: $liste_evenements_evenement_date_couleur;
        padding: 0 15px;
        border-top-left-radius: $input_radius * 2;
        border-top-right-radius: $input_radius * 2;
        position: relative;

        .number {
          font-size: 35px;
          padding-right: 5px;
          transform: translateY(-2px);
        }
      }
      .container {
        padding: 10px 15px;
        .content {
          font-weight: 600;
          color: $liste_evenements_evenement_description_couleur;
          font-size: 14px;
          padding-bottom: 8px;
        }

        .invitation {
          color: $liste_evenements_evenement_invitation_message;
          font-size: 14px;
          font-weight: 800;
          padding-top: 15px;
        }
        .participation {
          padding-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          gap:10px;
          button {
            width: 120px;
            color: $liste_evenements_evenement_invitation_boutons_couleur !important;
            background: none;
            border: 1px solid $liste_evenements_evenement_invitation_boutons_contour;
            &:hover{
              background:$liste_evenements_evenement_invitation_boutons_contour;
              background-color:$liste_evenements_evenement_invitation_boutons_contour;
              color:white !important
            }
          }
        }
      }
      .info,
      .actions .header {
        font-style: italic;
        font-size: 12px;
        font-weight: 600;
        color: $liste_evenements_evenement_options_couleur;
        &.datetime{

        }
      }
      .actions {
        .header {
          background: $liste_evenements_evenement_options_fond;
          height: 30px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          cursor: pointer;
          width: 100%;
          position: relative;
          svg {
            fill: $liste_evenements_evenement_options_couleur;
            transform: rotate(90deg);
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    min-height: calc(100vh - 115px);
    height: calc(100vh - 115px);
    // overflow: hidden;

    > .dk-grid:not(.breadcrumb) {
      display: flex;
      height: calc(100vh - 230px);
      overflow: hidden;
      #calendar {
        width: 66%;
      }
      #events {
        width: 34%;
        overflow: auto;
        padding-bottom: 50px;
      }
    }
  }

  > .focus {
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // background: rgba(0, 0, 0, 0.7);
    // z-index: 1003;
    ~ div #events {
      // z-index: 1004;
      .event {
        &:not(.focus) {
          .inner {
            box-shadow: none;
          }
          &::before {
            background: rgba(0, 0, 0, 0.15);
            z-index: 11;
          }
        }
      }
    }
  }
}

.true-false {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .swipe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .label {
    font-size: 12px;
    font-weight: 600;
    color: $liste_evenements_evenement_options_option_couleur;
    width: calc(100% - 85px);
  }
  .choice {
    width: 50px;
    margin-left: 35px;
    background: $liste_evenements_evenement_options_option_checkbox_fond;
    // box-shadow: inset 0 0 3px 0 #e9e9e9;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 12px;
    height: 24px;
    position: relative;
    cursor: pointer;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
    }
    .round {
      height: 20px;
      width: 20px;
      border-radius: 10px;
      box-shadow: 0 0 3px 0 #bebebe;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 2px;
      margin: auto;
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: $liste_evenements_evenement_options_option_checkbox_couleur_svg;
      }
      &.inactive {
        background: $liste_evenements_evenement_options_option_checkbox_couleur_inactif;
        svg {
          height: 9px;
          width: 9px;
        }
      }
      &.active {
        background: $liste_evenements_evenement_options_option_checkbox_couleur_actif;
        transform: translateX(26px);
        svg {
          width: 12px;
          height: 8px;
        }
      }
    }
  }
  ~ .header svg {
    transform: rotate(-90deg);
  }
}
