.stars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.color-star-dark {
    color: $bouton_ternaire_couleur;
}

.color-star-light {
    color: $bouton_secondaire_fond;
}