.common-shop {
  #header {
    .navbar-top {
      @media (min-width: 1200px) {
        background: $boutique_header_desktop_fond_degrade !important;
      }
      .svg {
        stop {
          &:last-child {
            stop-color: $boutique_header_titre_rubrique_fond_haut;
          }
          &:first-child {
            stop-color: $boutique_header_titre_rubrique_fond_bas;
          }
        }
      }
      .tab {
        &::before {
          background: $boutique_header_titre_rubrique_fond_haut;
        }
        .title {
          background: linear-gradient(
            to top,
            $boutique_header_titre_rubrique_fond_bas,
            $boutique_header_titre_rubrique_fond_haut
          );
        }
      }
      .dk-logo {
        &::before,
        &::after {
          display: none;
        }
      }
      .sticky {
        position: absolute;
        z-index: 2;
        top: 0;
        height: 50px;
        width: 80px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.home {
          left: 0;
          justify-content: flex-start;
          padding-left: 20px;
          padding-top: 10px;
          svg {
            fill: $boutique_sticky_maison_mobile_couleur;
            width: 25px;
            height: 25px;
            @media (min-width: 1200px) {
              fill: $boutique_sticky_maison_desktop_couleur;
            }
          }
          &.reverse {
            svg {
              transform: rotate(180deg);
            }
          }
        }
        @media (min-width: 1200px) {
          width: 25px;
          height: 25px;
          padding: 0 !important;
          top: 0;
          bottom: 0;
          margin: auto;
          cursor: pointer;
          &.home {
            left: auto !important;
            right: 100px;
            &.reverse {
              top:200px;
              left: calc(50vw - 539px) !important;
              right: auto;
              svg{
                fill:black
              }
            }
          }
          &.account {
            left: auto !important;
            right: calc(50vw - 539px);
            
          }
        }
        @media (min-width: 1400px) {
          &.home {
            //right: calc(50vw - 519px);
          }
        }
      }
    }
  }
  #searchbar {
    .opened {
      background: $boutique_searchbar_couleur_principale;
      &::before {
        background: $boutique_searchbar_couleur_secondaire;
      }
      #reset {
        color: $boutique_searchbar_couleur_textes;
      }
      @media (min-width: 1200px) {
        > span {
          color: $boutique_searchbar_couleur_textes;
        }
      }
    }
    .closed {
      .svg {
        svg path {
          fill: $boutique_searchbar_couleur_principale;
        }
      }
      .title {
        background: $boutique_searchbar_couleur_principale;
        color: $boutique_searchbar_couleur_textes;
        svg {
          fill: $boutique_searchbar_couleur_textes;
        }
      }
    }
  }

  .shop-access {
    background: $boutique_popup_fond;
    padding: 0;
    justify-content: flex-start;
    &::after,
    &::before {
      border: solid 130px $boutique_popup_cercles_fond;
    }
    .header {
      height: 280px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      background: $boutique_popup_header_fond;
      box-shadow: $boutique_popup_header_ombre;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      width: 100%;
      svg {
        * {
          fill: $boutique_popup_icones_couleur;
        }
        height: 62px;
        width: 62px;
        margin-bottom: 15px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        color: $boutique_popup_texte_couleur;
        line-height: 1.39;
      }
    }
    .actions {
      height: calc(100% - 280px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .btn-primary {
    background: #FFFFFF !important;
    color:var(--ui-primary-color) !important;
}
  .btn-second {
    background: var(--grey-color);
  }
}

.quantity-selector,
.filter-selector {
  position: relative;
  display: flex;
  cursor: pointer;
  > span {
    height: 38px;
    width: calc(100% - 38px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: $input-radius;
    border-bottom-left-radius: $input-radius;
    background: $boutique_selecteur_quantite_couleur_claire;
    font-size: 20px;
    color: #000;
    input {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #000;
      text-align: center;
      border: none;
      background: transparent;
      outline: none;
    }
  }
  > button {
    background: $boutique_selecteur_quantite_couleur_foncee;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: $input-radius;
    border-bottom-right-radius: $input-radius;
    svg {
      transform: rotate(90deg);
      fill: #fff;
      width: 16px;
      height: 16px;
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
  .options {
    position: absolute;
    top: 0;
    background: #fff;
    display: none;
    z-index: 2;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: calc(100% - 38px);
    border-top-left-radius: $input-radius;
    border-bottom-right-radius: $input-radius;
    border-bottom-left-radius: $input-radius;
    overflow: scroll;
    max-height: 200px;
    button {
      height: 38px;
      width: 100%;
      background: #fff;
      font-size: 20px;
      &.active {
        background: $boutique_selecteur_quantite_couleur_claire;
      }
      &.error {
        pointer-events: none;
        background: #df172f !important;
        color: #fff;
        cursor: inherit;
        display: none;
      }
    }
  }
  &.active {
    > button {
      svg {
        transform: rotate(-90deg);
      }
    }
    .options {
      display: block;
    }
  }
}

.filter-selector {
  min-width: 250px;
  max-width: 100%;
  > span {
    background: #fff;
    color: #002e5a;
    text-align: left;
    font-size: 14px;
    justify-content: flex-start;
    padding-left: 16px;
    padding-right: 16px;
  }
  .options {
    button {
      color: #002e5a;
      text-align: left;
      font-size: 14px;
      justify-content: flex-start;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  > button {
    background: #002e5a;
  }
}
