.template-directory {
  background: $annuaire_fond_page;
  &::before,
  &::after {
    opacity: 0.1;
  }
  #header {
    .navbar-top {
      
    }
  }
  #short-links-contacts {
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: space-around;
    font-size: 13px;
    font-weight: 800;
    div {
      color: $annuaire_shortlinks_lien_actif;
      padding-bottom: 5px;
      border-bottom: 5px solid $annuaire_shortlinks_lien_actif;
    }
    button {
      padding-bottom: 10px;
      background: transparent;
      color: $annuaire_shortlinks_lien_inactif;
      font-weight: 800;
      font-size: 13px;
    }
  }
  #alphabet {
    position: fixed;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    top: 120px;
    height: calc(100vh - 240px);
    button {
      background: none;
      font-size: 10px;
      color: $annuaire_alphabet_couleur;
      font-weight: 500;
    }
    @media (min-width: 1200px) {
      display: none;
    }
  }
  #contacts {
    padding: 0 0 30px;
    > a {
      width: 100%;
      flex: 100%;
      max-width: 100%;
      @media (min-width: 1200px) {
        flex: 50% 1;
        max-width: 50%;
        width: 50%;
      }
    }
    .letter {
      flex: 100%;
      width: calc(100% + 20px);
      max-width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;
      background: #f4f4f4;
      font-size: 16px;
      font-weight: bold;
      color: $annuaire_lettre_couleur;
      padding: 5px 28px;
      margin-top: 20px;
      @media (min-width: 700px) {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 5px 18px;
      }
    }
    // .letter {
    //   > span {
    //     font-size: 11px;
    //     font-weight: 500;
    //     color: $annuaire_lettre_couleur;
    //     padding-bottom: 5px;
    //   }
    // }
    .contact {
      padding-right: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 10px 0;
      &.inactive {
        opacity: 0.3;
      }

      .favorite {
        width: 20px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        height: 100%;
        svg {
          width: 14px;
          height: 14px;
          fill: $annuaire_favoris_icone;
          transform: translate(-3px, 1px);
        }
      }
      .informations {
        display: flex;
        align-items:flex-end;
        justify-content: space-between;
        width: calc(100% - 20px);
        padding-bottom: 8px;
        border-bottom: 1px solid $annuaire_ligne_separateur;
        .name {
          font-size: 15px;
          font-weight: 800;
          color: $annuaire_contact_couleur;
          span {
            font-size: 12px;
            font-weight: normal;
            font-style: italic;
          }
        }
        .company {
          font-size: 12px;
          color: $annuaire_contact_couleur;
          line-height: 15px;
        }
        .filter {
          color: $annuaire_contact_couleur;
          font-size: 10px;
          font-weight: 500;
        }
        .cagnotte{
          color:#000
        }
      }
    }
  }
  @media (min-width: 1200px) {
    #short-links-contacts {
      padding: 0;
      * {
        width: calc(100% / 3);
        text-align: center;
      }
    }
    #contacts {
      .contact {
        cursor: pointer;
      }
    }
  }
}

.contacts-filters {
  display: flex;
  justify-content: center;
  > button {
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 10px 14px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    display: none;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px !important;
    border-radius: 50%;
    margin-top: 4px;
    svg {
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &.active,
    &:hover {
      background: $annuaire_filtre_actif_fond;
      color: #fff;

      svg {
        fill: #fff;
      }
    }
    &:first-child {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .container {
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    max-width: calc(100% + 20px);
    width: auto;
    overflow: hidden;
    display: flex;
    padding-bottom: 15px;
    &::-webkit-scrollbar {
      display: none;
    }
    .inner {
      display: flex;
    }
    button {
      border-radius: $input-radius * 2;
      padding: 8px 15px;
      margin: 4px 8px;
      background: #f7f7f7;
      font-size: 12px;
      font-weight: 500;
      color: #000;
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      white-space: nowrap;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      &.active,
      &:hover {
        background: $annuaire_filtre_actif_fond;
        color: #fff;
        box-shadow: 0 10px 14px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      }
    }
    @media (min-width: 1200px) {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  &.scrollable {
    justify-content: space-between;
    > button {
      display: flex;
    }
    .container {
      max-width: calc(100% - 80px);
    }
  }
}
