.template-polls {
  .poll {
    width: 100%;
    max-width: 100%;
    flex: 100%;
    margin: 20px 0;
    padding: 0;
    @media (min-width: 1200px) {
      width: 50%;
      flex: 50%;
      padding: 15px;
      margin: 0 0;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      pointer-events: none;
      z-index: 11;
      border-radius: $input-radius * 2;
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      @media (min-width: 1200px) {
        top: 15px;
        bottom: 15px;
        left: 15px;
        right: 15px;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
      }
    }
    &.focus {
      z-index: 1004;
    }
    .badge-new-post {
      transform: none;
      top: 0;
      left: 8px;
    }
    .inner {
      overflow: hidden;
      border-radius: $input-radius * 2;
      background: $sondage_bloc_fond;
      box-shadow: 0 10px 10px 0 rgba(177, 177, 177, 0.5);
      position: relative;
      .admin-bar {
        > span {
          height: 32px;
          border-bottom-left-radius: 0;
        }
      }
    }
    .date {
      height: 32px;
      display: flex;
      background: $sondage_header_fond;
      justify-content: flex-end;
      span {
        background: $sondage_date_inactive_fond;
        min-width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $sondage_date_inactive_couleur;
        font-weight: 600;
        font-size: 10px;
      }
    }
    .moderation {
      background: $sondage_date_inactive_fond !important;
    }
    .title {
      border-bottom: 1px solid #e9e9e9;
      padding: 10px 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.2;
      color: $sondage_bloc_couleur;
    }
    .answers {
      .answer {
        border-bottom: 1px solid #e9e9e9;
        padding: 5px 0;
        button {
          background: none;
          font-size: 13px;
          color: $sondage_bloc_couleur;
          font-weight: 600;
          padding: 10px 15px;
          width: 100%;
          text-align: left;
          position: relative;
        }
        .votes {
          padding: 0 15px 0;
          margin-top: -5px;
          color: $sondage_bloc_votes_couleur;
          align-items: center;
          display: none;
          .line {
            height: 3px;
            background: $sondage_bloc_votes_couleur;
            border-radius: 1px;
          }
          .number {
            padding-left: 5px;
            font-size: 11px;
            font-weight: 800;
          }
        }
        &.active {
          button {
            color: $sondage_bloc_actif_couleur;
          }
          .votes {
            color: $sondage_bloc_actif_couleur;
            .line {
              background: $sondage_bloc_actif_couleur;
            }
          }
        }
        &.draft {
          pointer-events: none;
          cursor: alias;
          button {
            padding: 10px 15px;
            &::before,
            &::after {
              display: none;
            }
          }
        }
      }
      .submit {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .total {
      font-size: 10px;
      font-weight: 600;
      padding: 8px 15px;
      color: $sondage_bloc_total_couleur;
      display: none;
    }
    &.active {
      .date {
        span {
          background: $sondage_date_active_fond;
          color: $sondage_date_active_couleur;
        }
      }
      .answers {
        .answer {
          .votes {
            display: flex;
          }
        }
        .submit {
          display: none;
        }
      }
      .total {
        display: block;
      }
    }
    &.inactive {
      .answer {
        button {
          padding-left: 45px;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 15px;
            bottom: 0;
            margin: auto;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid $sondage_bloc_couleur;
            transform: translateY(-1px);
            transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          }
          &::after {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $sondage_bloc_fond;
            left: 20px;
            transform: translateY(-1px);
            transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          }
        }
        &.selected {
          button {
            &::before {
              border-color: $sondage_bloc_actif_couleur;
            }
            &::after {
              background: $sondage_bloc_actif_couleur;
            }
          }
        }
      }
    }
  }

  > .focus {
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // background: rgba(0, 0, 0, 0.7);
    // z-index: 1003;
    ~ #posts {
      // z-index: 1004;
      .poll {
        &:not(.focus) {
          .inner {
            box-shadow: none;
          }
          &::before {
            background: rgba(0, 0, 0, 0.2);
            z-index: 11;
          }
        }
      }
    }
  }
}
