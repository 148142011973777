.common-private {
  // background: $prive_fond_pages;
  min-height: 100vh;
  margin-top: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;

  .link-back {
    z-index: 3;
    top: 20px;
    left: 20px;
  }

  @media (min-width: 700px) and (max-width: 1200px) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    margin-top: 115px !important;
    padding-top: 75px !important;
    padding-left: 0;
    padding-right: 0;
  }
}
