#notification {
  position: fixed;
  top: calc(50px + 20px);
  right: 20px;
  z-index: 1000;
  background: #00a3d4;
  color: #fff;
  border-radius: $input_radius;
  min-width: 240px;
  max-width: 300px;
  padding: 10px 18px 15px;
  overflow: hidden;
  box-shadow: 0 10px 10px 0 rgba(177, 177, 177, 0.5);
  animation: notifOut 0.3s forwards 2.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .timer {
    height: 5px;
    background: #000;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    animation: timerOut 2.5s forwards linear;
    transform-origin: right;
  }
  @media (min-width: 1200px) {
    top: calc(115px + 20px);
  }
}

@keyframes timerOut {
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
}

@keyframes notifOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
