.template-mega-menu {
  min-height: auto;
  &:not(.main-menu) {
    #header .navbar-top {
      .toggle {
        display: none;
      }
    }
  }
  #header .navbar-top {
    background: #fff;
  }

  #header.active {
    ~ .back {
      display: none;
    }
  }
  .breadcrumb {
    @media (min-width: 1200px) {
      padding-bottom: 20px;
    }
  }

  @media (min-width: 1200px) {
    .dk-grid {
      width: 600px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.dk-grid.back {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  height: 50px;
  width: 80px;
  button,
  a {
    display: flex;
    background: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 12px;
    svg {
      height: 24px;
      width: 24px;
      margin-right: 12px;
      transform: rotate(180deg);
      fill: $menu_burger_couleur;
    }
    span {
      display: none;
      color: $menu_burger_couleur;
    }
  }
  @media (min-width: 1200px) {
    height: auto;
    position: fixed;
    left: 360px;
    top: 160px;
    z-index: 1001;
    width: auto;
    transform: none !important;
    button,
    a {
      width: auto;
      justify-content: flex-start;
      padding-top: 0;
      svg {
        margin-right: 5px;
      }
      span {
        display: flex;
      }
    }
  }
}

.mega-menu {
  position: relative;

  &--container {
    display: none;
    position: relative;
    // display: flex;
    // position: fixed;
    // top: 0;
    width: 100%;
    // left: 0;
    // height: 100vh;
    overflow: hidden;
    // background: #fff;
    // z-index: 10;
    flex-direction: column;
    // padding-top: 80px;

    @media (min-width: 1200px) {
      // background: transparent;
      // position: relative;
      // width: calc(100vw - 320px);
      // max-width: 100%;
      // right: 0;
      // left: 320px;
      // padding-top: 250px;
      // height: auto;
      // padding-top: 0;
    }
  }
  &--menu {
    // padding-left: 15px;
    // padding-right: 15px;
    display: flex;
    @media (min-width: 1200px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    button,
    a {
      padding: 12px 20px;
      width: 100%;
      border-radius: $input_radius * 2;
      background: #f4f4f4;
      margin: 7px 0;
      font-weight: 600;
      text-align: left;
      font-size: 14px;
      color: #000;
    }
  }

  &--section-title {
    font-weight: bold;
    margin-bottom: 6px;
  }

  &--section {
    margin-top: 16px;

    #posts {
        transform: translateX(0) !important;
    }
  }

  #posts  > :first-child.post {
    padding-left: 3px
  }

  &.menu-active {
    .mega-menu {
      &--container {
        display: flex;
      }
    }
  }
}
