// NOUVELLE NOMENCLATURE COULEURS

// Pages privées
$prive_cercle: #f9f9f9;
$prive_fond_pages: #fff;

// Pages de connexion / inscription
$connexion_couleur_titre: #002e5a;
$connexion_fond_page: #fff;
$connexion_cercle: rgba(#00b1eb, 0.05);
$connexion_header_fond: linear-gradient(to bottom, #002e5a -25%, #004f9f 125%);
$connexion_header_shadow: #002e5a;
$connexion_premiere_bouton_fond: #00b1eb;
$connexion_premiere_bouton_text:#fff;
$connexion_desktop_logo_height :55px;
//Privacy policy
$privacy_header_fond:linear-gradient(to bottom, #002e5a -25%, #004f9f 125%);
$privacy_policy_arrow_back:#FFF;

// Boutons
$bouton_couleur_texte: #fff;
$bouton_secondary_couleur_texte:#fff;
$bouton_contour: 23.5px;
$bouton_principal_fond: linear-gradient(to top, #002e5a, #004f9f);
$bouton_principal_lighter_fond: linear-gradient(to top, #004f9f, #00b1eb);
$bouton_secondaire_fond: #c7c7c7;
$bouton_ternaire_couleur: #002e5a;

// TODO
$lien_couleur_defaut: #fff;

// Commun
$fleche_retour_couleur: #fff;
$input_radius: 5px;
$desktop_petit_cercle_couleur: #adadad;
$desktop_petit_cercle_opacite: 0.15;
$desktop_grand_cercle_couleur: #ededed;
$desktop_grand_cercle_opacite: 0.35;

// Popup
$popup_fond: #fff;
$popup_descriptif_fond: #e4e4e4;
$popup_cercle_milieu: rgba(#f9f9f9, 0.05);

// Menu
$menu_mobile_fond: linear-gradient(to right, #fff 50%, #f9f9f9 50%);
$menu_desktop_fond: #fff;
$menu_desktop_logo_height: 55px;
$menu_desktop_titre_fond_haut: #004f9f;
$menu_mobile_titre_fond: linear-gradient(to top, #002e5a, #004f9f);
$menu_mobile_titre_couleur: #fff;
$menu_favori_inactif: #fff;
$menu_nouveau_post_contour: #002e5a;
$menu_nouveau_post_couleur: #002e5a;
$menu_desktop_nouveau_post_contour: #002e5a;
$menu_desktop_nouveau_post_couleur: #002e5a;
$menu_favori_actif: #fff;
$menu_fermer_burger: #fff;
$menu_burger_contour: #e9e9e9;
$menu_burger_couleur: #002e5a;
$menu_burger_icone_valider_recherche: #002e5a;
$menu_burger_liens_couleur_texte: #fff;
$menu_burger_liens_couleur_fond: linear-gradient(to bottom, #004f9f, #002e5a);
$menu_background:linear-gradient(to bottom, #004f9f, #002e5a);
$menu_burger_liens_couleur_chevrons: #fff;
$menu_burger_liens_couleur_rubriques: #7ea0c3;
$menu_burger_liens_couleur_bordures_rubriques: #7ea0c3;
$menu_burger_fond_header: #004f9f;
$menu_burger_titre_fond_haut: #b1b1b1;
$menu_burger_titre_fond_bas: #e9e9e9;
$menu_burger_titre_couleur: #000;
$menu_reseaux_fond_header: linear-gradient(to top, #013d7b, #004f9f);
$menu_reseaux_fond_container: linear-gradient(to top, #002e5a, #013d7b);
$menu_reseaux_icone_couleur: #002e5a;

// Calendrier
$calendrier_fond_page: #fff;
$calendrier_mois_couleur_inactif: #bebebe;
$calendrier_mois_couleur_actif: #002e5a;
$calendrier_jour: #bebebe;
$calendrier_date: #4a4a4a;
$calendrier_date_active_couleur: #fff;
$calendrier_date_active_fond: #002e5a;

// Liste des évènements dans la page Agenda
$liste_evenements_mois_fond: #e9e9e9;
$liste_evenements_mois_couleur: #9b9b9b;
$liste_evenements_evenement_fond: #fff;
$liste_evenements_evenement_date_fond: #002e5a;
$liste_evenements_evenement_date_couleur: #fff;
$liste_evenements_evenement_description_couleur: #4a4a4a;
$liste_evenements_evenement_invitation_message: #004f9f;
$liste_evenements_evenement_invitation_boutons_contour: #002e5a;
$liste_evenements_evenement_invitation_boutons_couleur: #002e5a;
$liste_evenements_evenement_options_couleur: #9b9b9b;
$liste_evenements_evenement_options_fond: #e9e9e9;
$liste_evenements_evenement_options_option_couleur: #979797;
$liste_evenements_evenement_options_option_checkbox_fond: #e9e9e9;
$liste_evenements_evenement_options_option_checkbox_couleur_svg: #170f23;
$liste_evenements_evenement_options_option_checkbox_couleur_inactif: #f55353;
$liste_evenements_evenement_options_option_checkbox_couleur_actif: #00b1eb;

// ShortLinks (home + page photos)
$shortlinks_lien_inactif: #9b9b9b;
$shortlinks_lien_actif: #004f9f;

// Accueil
$accueil_article_fond: #fff;
$accueil_article_fleche_couleur: #fff;
$accueil_boutique_article_fleche_couleur:var(--blank-color);
$accueil_article_fleche_fond: #002e5a;
$accueil_article_texte_couleur: #000;
$accueil_article_bar_admin_icone_fond: #eeeeee;
$accueil_article_bar_admin_icone_couleur: #333333;
$accueil_article_important_texte_couleur: #fff;

$accueil_text-like:#002e5a;

// Barre de recherche
$searchbar_fond: #002e5a;
$searchbar_inactive_couleur: #fff;
$searchbar_active_couleur: #000;
$searchbar_annuler_couleur: #fff;
$searchbar_couleur_simulateur_micro: #013d7b;

// Annuaires
$annuaire_fond_page: #fff;
$annuaire_shortlinks_lien_inactif: #9b9b9b;
$annuaire_shortlinks_lien_actif: #000;
$annuaire_alphabet_couleur: #9b9b9b;
$annuaire_lettre_couleur: #000;
$annuaire_contact_couleur: #000;
$annuaire_favoris_icone: #00b1eb;
$annuaire_ligne_separateur: #cccccc;
$annuaire_popup_nom_couleur: #fff;
$annuaire_popup_icone_secondaire_fond: linear-gradient(168deg, #e3e3e3 -1%, #acacac 102%);
$annuaire_popup_icone_secondaire_couleur: #002e5a;
$annuaire_popup_icone_principale_fond: #004f9f;
$annuaire_popup_icone_principale_couleur: #fff;
$annuaire_desktop_raccourcis_icones: #fff;
$annuaire_filtre_actif_fond: linear-gradient(to bottom, #004f9f, #002e5a);

// Life
$life_popup_filtres_couleur: #fff;
$life_popup_filtres_fond: #002e5a;
$life_popup_fermer_couleur: #fff;
$life_popup_fermer_fond: #002e5a;

// Article
$article_fond_page: #f5f6f5;
$article_cercle: #e1e1e1;
$article_texte_couleur: #000;
$article_commentaire_couleur: #000;
$article_icone_retour: #002e5a;
$article_sticky_likes_couleur: #fff;
$article_sticky_likes_fond: #002e5a;
$article_formulaire_fond: #fff;
$article_likes_commentaires_couleur: #000;
$article_galerie_fleches_fond: #00b1eb;
$article_galerie_fleches_color:#fff;
$article_hashtag_fond: #002e5a;

// Profil
$profil_cercle: linear-gradient(to bottom, #004f9f, #002e5a 102%);
$profil_information_fond: #fff;
$profil_information_couleur: #4a4a4a;
$profil_information_annuler: #f55353;
$profil_information_valider: #00b1eb;
$profil_burger_couleur: #00b1eb;
$profil_cgu_icone_couleur: #fff;
$profil_cgu_icone_fond: #002e5a;
$profil_cgu_couleur: #fff;
$profil_cgu_fond: #002e5a;
$profil_edition_photo_fond: #fff;
$profil_edition_photo_couleur: #002e5a;
$profil_edition_photo_ombre: #fff;
$profil_snooze_header_fond: #3d3d3d;
$profil_snooze_header_couleur: #fff;
$profil_sooze_icone_inactive_fond: #00b1eb;
$profil_sooze_icone_inactive_couleur: #fff;
$profil_sooze_icone_active_fond: #9b9b9b;
$profil_sooze_icone_active_couleur: #4a4a4a;
$profil_snooze_fond: #9b9b9b;
$profil_snooze_couleur: #fff;
$profil_offline_icone_fond: #a01c11;
$profil_offline_icone_couleur: #fff;
$profil_online_icone_fond: #00b1eb;
$profil_online_icone_couleur: #fff;
$profil_background_badge_pencil: #fff;

// Administration
$administration_menu_desktop_titre_fond_haut: #b1b1b1;
$administration_cercle: #b9b9b9;
$administration_fond_page: #fff;
$administration_menu_titre_fond: linear-gradient(to top, #e9e9e9, #b1b1b1);
$administration_menu_titre_couleur: #000;
$administration_menu_burger_couleur: #fff;
$administration_menu_annuler_fond: #000;
$administration_menu_annuler_couleur: #fff;
$administration_bloc_fond: #e9e9e9;
$administration_etapes_icone_fond: #002e5a;
$administration_etapes_icone_couleur: #fff;
$administration_etapes_header_item_inactif_couleur: #9b9b9b;
$administration_etapes_header_item_actif_couleur: #fff;
$administration_etapes_header_item_actif_fond: #002e5a;
$administration_etapes_categorie_icone_fond_inactif: #9b9b9b;
$administration_etapes_categorie_icone_fond_actif: #002e5a;
$administration_etapes_categorie_icone_couleur: #fff;
$administration_label_couleur: #000;
$administration_etapes_indicateur_fond_inactif: #9b9b9b;
$administration_etapes_indicateur_fond_actif: #002e5a;
$administration_input_fond: #fff;
$administration_input_datepicker_fond_actif: #002e5a;
$administration_input_datepicker_couleur_actif: #fff;
$administration_input_import_fichier_fond: #e9e9e9;
$administration_input_import_fichier_bordure: #002e5a;
$administration_input_import_fichier_couleur: #002e5a;
$administration_input_email_contour: #979797;
$administration_input_email_actif: #979797;
$administration_destinataires_label_couleur: #000;
$administration_destinataires_accordeon_fond: #002e5a;
$administration_destinataires_accordeon_couleur: #fff;
$administration_destinataires_checkbox_contour: #fff;
$administration_destinataires_checkbox_active: #fff;
$administration_hashtag_fond: linear-gradient(to bottom, #004f9f, #002e5a);
$administration_hashtag_couleur: #fff;
$administration_hashtag_supprimer_couleur: #002e5a;
$administration_suggestion_fond: linear-gradient(to bottom, #004f9f, #002e5a);
$administration_suggestion_couleur: #fff;
$administration_reseaux_sociaux_actif_fond: linear-gradient(to bottom, #004f9f, #002e5a);
$administration_reseaux_sociaux_actif_couleur: #fff;

// Commun formulaires
$input_couleur: #000000;
$input_fond: #e4e4e4;

// Loader
// TODO
$loader_darken_color: #002e5a;

// Tutoriel d'installation
$tuto_background: linear-gradient(to top, #002e5a 10%, #004f9f 75%);
$tuto_text_color: #fff;
$tuto_footer_background: #002e5a;

// Sondages
$sondage_header_fond: #e9e9e9;
$sondage_date_inactive_fond: #002e5a;
$sondage_date_inactive_couleur: #ffffff;
$sondage_date_active_fond: #00b1eb;
$sondage_date_active_couleur: #ffffff;
$sondage_bloc_fond: #ffffff;
$sondage_bloc_couleur: #3d3d3d;
$sondage_bloc_actif_couleur: #00b1eb;
$sondage_bloc_votes_couleur: #bbbbbb;
$sondage_bloc_total_couleur: #9b9b9b;
$sondage_filtre_actif_fond: #002e5a;
$sondage_filtre_titre_couleur: #002e5a;
$sondage_filtre_choix_couleur: #002e5a;

// TODO
$badge_fond: #f55353;
// TODO
$badge_couleur: #fff;

// TODO
$badge_nouveaute_fond: #f55353;
// TODO
$badge_nouveaute_couleur: #fff;

$aucun_contenu_couleur: #002e5a;

// Partage sur les réseaux sociaux
$liste_hashtags_fond: #9b9b9b;
$article_choix_reseau_bordure: #002e5a;
$article_choix_reseau_fond: linear-gradient(to top, #002e5a, #004f9f);

// Boutique - HEADER
$boutique_header_desktop_fond_degrade: #00b1eb;
$boutique_header_titre_rubrique_fond_haut: #00b1eb;
$boutique_header_titre_rubrique_fond_bas: #00b1eb;
$boutique_sticky_maison_desktop_couleur: #fff;
$boutique_sticky_maison_mobile_couleur: #00b1eb;

// Boutique - SEARCHBAR
$boutique_searchbar_couleur_principale: #002e5a;
$boutique_searchbar_couleur_secondaire: #013d7b;
$boutique_searchbar_couleur_textes: #fff;

// Boutique - POPUP
$boutique_popup_fond: linear-gradient(to bottom, #004f9f 0%, #00b1eb 100%);
$boutique_popup_cercles_fond: rgba(249, 249, 249, 0.05);
$boutique_popup_header_fond: #fff;
$boutique_popup_header_ombre: 0 10px 20px rgba(0, 0, 0, 0.19),
0 6px 6px rgba(0, 0, 0, 0.23);
$boutique_popup_icones_couleur: #00b1eb;
$boutique_popup_texte_couleur: #00b1eb;

// Boutique - BOUTONS
$boutique_bouton_principal_fond: linear-gradient(to top, #004f9f, #00b1eb);
$boutique_bouton_secondaire_fond: #c7c7c7;

// Boutique - SELECTEUR QUANTITE
$boutique_selecteur_quantite_couleur_foncee: #00b1eb;
$boutique_selecteur_quantite_couleur_claire: #cceffb;

// Boutique - STICKY ACCES BOUTIQUE / PANIER
$boutique_sticky_acces_boutique_fond: #00b1eb;
$boutique_sticky_acces_panier_fond: #002e5a;
$boutique_sticky_acces_icone_couleur: #fff;
$boutique_sticky_acces_icone_couleur_outer: #fff;
$boutique_sticky_acces_badge_couleur: #fff;
$boutique_sticky_acces_badge_fond: #f55353;

// Boutique - LISTE DES PRODUITS
// $boutique_filtres_couleur_principale: #0e6157;
// $boutique_filtres_couleur_secondaire: #1addc6;
$boutique_preview_produit_couleur_principale: #000;
$boutique_preview_produit_couleur_secondaire: #00b1eb;
$boutique_preview_produit_couleur_alerte: #f55353;
$boutique_preview_produit_couleur_alerte_indisponible: #a9a9a9;
$boutique_preview_produit_couleur_prix_raye: #a9a9a9;

// Boutique - PAGE PRODUIT
$boutique_produit_icone_retour_couleur: #00b1eb;
$boutique_produit_sticky_tarif_raye_fond: #a9a9a9;
$boutique_produit_sticky_tarif_fond: #00b1eb;
$boutique_produit_sticky_tarif_couleur: #fff;
$boutique_produit_sticky_tarif_promo_fond: #f55353;
$boutique_produit_sticky_tarif_promo_couleur: #fff;
$boutique_produit_liens_fond: #002e5a;
$boutique_produit_compte_a_rebours_couleur: #000;
$boutique_produit_avertissement_couleur: #000;
$boutique_produit_stock_couleur: #fff;
$boutique_produit_stock_fond: #f55353;
$boutique_produit_prix_fond: #f55353;
$boutique_produit_prix_couleur: #fff;
$boutique_produit_personnaliser_fond: #fff;
$boutique_produit_personnaliser_couleur: #002e5a;
$boutique_produit_ajout_panier_fond: #00b1eb;
$boutique_produit_ajout_panier_couleur: #fff;
$boutique_produit_personnalisation_produit_couleur_principale: #00b1eb;
$boutique_produit_color_total_line:#00b1eb;
$boutique_produit_color_total:#00b1eb;
$boutique_produit_personnalisation_participants_couleur_principale: #002e5a;

// Boutique - PRIX DEGRESSIFS
$boutique_prix_deg_fond_filtre: #cceffb;
$boutique_prix_deg_couleur_1: #00b1eb;
$boutique_prix_deg_couleur_2: #004f9f;
$boutique_prix_deg_couleur_3: #002e5a;

// Boutique - PANIER
$boutique_panier_titre_couleur: #000;
$boutique_panier_promo_valider_fond: #00b1eb;
$boutique_panier_promo_valider_couleur: #fff;
$boutique_panier_promo_message_couleur: #f55353;
$boutique_panier_produit_suppression_fond: #f55353;
$boutique_panier_produit_suppression_couleur: #fff;
$boutique_panier_service_suppression_couleur: #f55353;
$boutique_panier_validation_fond: #00b1eb;
$boutique_panier_validation_couleur: #fff;


$ca_color_1:#00b1eb;
$ca_color_2:#004f9f;
$ca_color_3:#002e5a;



//JEUX DE COLOR POUR MONDIAL // DEBUT
$color_primary:#002e5a;
$color_secondary:#004f9f;
$color_thirty:#00b1eb;
$color_fourth:#f55353;