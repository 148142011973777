#shopping-bag {
  position: fixed;
  bottom: 60px;
  right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  height: 47px;
  width: 47px;
  transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 12;
  &.outer-shop {
    background: $boutique_sticky_acces_boutique_fond;
    svg {
      width: 23px;
      height: 28px;
      fill: $boutique_sticky_acces_icone_couleur_outer;
    }
  }
  &.inner-shop {
    background: $boutique_sticky_acces_panier_fond;
    svg {
      width: 23px;
      height: 28px;
      fill: $boutique_sticky_acces_icone_couleur;
    }
  }
  /*svg {
    width: 23px;
    height: 28px;
    fill: $boutique_sticky_acces_icone_couleur;
  }*/

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $boutique_sticky_acces_badge_couleur;
    background: $boutique_sticky_acces_badge_fond;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    font-weight: 500;
    font-size: 12px;
    width: 16px;
    height: 16px;
    transform: translate(10%, 10%);
    @media (min-width: 1200px) {
      font-size: 15px;
      width: 22px;
      height: 22px;
      transform: translate(30%, 30%);
    }
  }

  @media (min-width: 1200px) {
    bottom: auto;
    top: calc(115px / 2);
    position: fixed;
    z-index: 1000;
    transform: translateY(-50%);
    right: 20px;
  }

  @media (min-width: 1400px) {
    //right: calc(50vw - 600px);
    // bottom: auto;
    // top: 300px;
    // &.outer-shop {
    // right: calc(50vw - 700px);
    // }
    // &.inner-shop {
    //   right: calc(50vw - 550px);
    // }
    // &:hover {
    //   transform: scale(1.2);
    // }
  }
}
