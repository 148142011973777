.template-post {
  margin-top: 0;
  padding-top: 0;
  background: $article_fond_page;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  @media (min-width: 700px) and (max-width: 1200px) {
    background: transparent;
  }
  &::before,
  &::after {
    opacity: 0.2;
    border-color: $article_cercle !important;
  }
  .link-back {
    background: transparent;
    display: flex;
    align-items: center;

    svg {
      fill: $article_icone_retour;
      transform: rotate(180deg);
      margin-right:5px;
    }
    span {
      display: none;
      color: $article_icone_retour;
      @media (min-width: 1200px) {
        display: flex;
      }
    }
    @media screen and (max-width:1200px) {
      background: #FFFFFF;
      border-radius: 50%;
      width:40px;
      height: 40px; 
      justify-content: center;
      svg{
        margin-right: 0px;
      }
    }
  }
  .post-loader-container {
    padding: 20px;
  }
  .popup.picture {
    .container {
      box-shadow: none;
      padding: 0;
      background: transparent;
      width: auto;
      @media (min-width: 1200px) {
        max-width: 600px;
      }
    }
    .visuel {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      width: auto;
      img {
        width: auto;
        max-width: 100%;
        max-height: 80vh;
      }
    }
    .close {
      right: 0;
      top: 0;
      display: none;
    }
  }
  .visuel {
    height: auto;
    align-items: flex-start;
    .admin-bar {
      right: 0;
      > span {
        border-top-right-radius: 0;
      }
    }
    img {
      border-bottom-left-radius: $input_radius * 2;
      border-bottom-right-radius: $input_radius * 2;
      max-width: 100%;
      max-height: 70vh;
      margin: auto;
      // height: auto;
      object-fit: contain;
      @media (min-width: 1200px) {
        max-height: 50vh;
        border-radius: $input_radius * 2;
      }
    }
    display: flex;
    position: relative;
    min-height: 80px;
    .links {
      position: absolute;
      right: 10px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .likes,
    .url,
    .doc {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      color: $article_sticky_likes_couleur;
      background: $article_sticky_likes_fond;
      padding: 10px;
      border-top-right-radius: $input_radius * 3;
      border-bottom-left-radius: $input_radius * 3;
      transform: translateY(10px);
      font-size: 13px;
      height: 51px;
      margin: 0 10px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      svg {
        fill: none;
        stroke: $article_sticky_likes_couleur;
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        width: 16px;
        height: 16px;
      }
      &.active {
        animation: bounceLike 0.5s;
        svg {
          fill: $article_sticky_likes_couleur;
        }
      }
    }
    .url,
    .doc {
      svg {
        fill: $article_sticky_likes_couleur;
        stroke: none;
      }
    }
  }
  .post {
    padding: 10px 20px 20px;

    h1 {
      font-size: 20px;
      line-height: 22px;
      font-weight: 800;
      text-transform: none;
      + .external-links {
        margin-top: 20px;
      }
    }
    .content {
      * {
        font-family: 'Raleway', sans-serif !important;
      }
      
      .container-video {
        margin-top: 20px;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      }

      .aligncenter{
        display: block;
        margin:0 auto;
      }
      
      /* Then style the iframe to fit in the container div with full height and width */
      .iframe-video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
      h2 {
        margin: 20px 0 10px;
      }
      p {
        margin-block-start: 14px;
        margin-block-end: 14px;

      }

      a {
        text-decoration: underline;
      }
      padding: 8px 0 30px;
      line-height: 16px;
    }
    .external-links {
      display: flex;
      // flex-direction: column;
      align-items: flex-start;
      // margin-top: -30px;
      padding-bottom: 25px;
      margin-left: -5px;
      margin-right: -5px;
      flex-wrap: wrap;
      justify-content: center;
      @media (min-width: 1200px) {
        justify-content: flex-start;
      }
      a {
        display: flex;
        align-items: center;
        padding: 10px;
        border-top-right-radius: $input_radius * 3;
        border-bottom-left-radius: $input_radius * 3;
        background: $article_sticky_likes_fond;
        color: #fff;
        margin: 5px;
        font-size: 12px;

        svg {
          fill: $article_sticky_likes_couleur;
          transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }

    .auteur,
    .date {
      font-size: 12px;
    }

    .actions-post {
      padding: 22px 0 12px;
    }
    .likes-list {
      padding-bottom: 10px;
      button,
      span {
        background: none;
        color: $accueil_text-like;
        font-weight: 600;
        font-size: 11px;
      }
    }
    * {
      color: $article_texte_couleur;
    }
  }
  .comments {
    padding: 10px 0;
    .comment {
      padding: 10px 0;
      p {
        color: $article_commentaire_couleur;
        &.author {
          font-weight: 800;
          font-size: 12px;
        }
        &.date {
          font-size: 12px;
          padding-bottom: 8px;
        }
        &.content {
          font-size: 14px;
          padding: 0;
        }
      }
      .moderate-actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: -10px;
        button {
          margin: 10px;
        }
      }
    }
  }
  form {
    textarea {
      background: $article_formulaire_fond;
      margin-bottom: 20px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }

  @media (min-width: 1200px) {
    .link-back {
      position: fixed;
      left: 360px;
      top: 160px;
      z-index: 1001;
    }
    .comments {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
      .comment {
        width: calc(100% / 3);
        padding: 10px;
      }
    }
  }
}

@keyframes bounceLike {
  0% {
    transform: scale(1) translateY(10px);
  }
  50% {
    transform: scale(1.2) translateY(10px);
  }
  100% {
    transform: scale(1) translateY(10px);
  }
}

.socialtemplate {
  .visuel {
    margin-bottom: 30px;
    img {
      z-index: 2;
    }
  }
  .social-icon {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 100%;
    width: 100%;
    margin-top: -10px;
    padding-right: 40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-bottom-left-radius: $input_radius * 2;
    border-bottom-right-radius: $input_radius * 2;
    padding-top: 10px;
    &.facebook {
      background: #2e4a85;
    }
    &.linkedin {
      background: #2b89c9;
    }
    svg {
      height: 20px;
      width: 20px;
      fill: #fff;
    }
  }
  &--tags {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    margin-right: -3px;
    margin-left: -3px;
    span {
      font-size: 12px;
      padding: 3px 6px;
      margin: 3px;
      line-height: 1.3;
      background: $article_hashtag_fond;
      color: #fff !important;
      border-radius: $input_radius;
    }
  }
  &--publish {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 20px;
    border-top: 1px solid #bababa;
    width: 100%;
    button {
      height: 38px;
    }
  }
  .h1 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 800;
    text-transform: none;
    text-align: center;
    margin-bottom: 5px;
    padding: 18px 50px;
    border-bottom: 1px solid #bababa;
    @media (min-width: 1200px) {
      padding: 20px;
    }
  }
  &--customization {
    form {
      padding: 20px;
    }
  }
  &--accounts {
    > p:not(.h1) {
      text-align: center;
      padding: 20px 20px 0;
    }
    .loader-container {
      position: relative;
      height: 100px;
      width: 100%;
    }
    .socialtemplate--publish {
      border: none;
    }
    &.list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .account {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 15px;
        max-width: 100%;
        width: 100%;
        @media (min-width: 1200px) {
          max-width: 50%;
          width: 50%;
        }

        img {
          border-radius: 50%;
          height: 50px;
        }
        button {
          background: transparent;
          margin-top: 10px;
          border: 1px solid $article_choix_reseau_bordure;
          color: $article_choix_reseau_bordure !important;
          transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
        &:not(.selected) button:hover {
          background: $article_choix_reseau_fond;
          color: #fff !important;
        }
        &.selected {
          width: 100%;
          max-width: 100%;
          button {
            background: $article_choix_reseau_fond;
            color: #fff !important;
          }
        }
      }
      @media (min-width: 1200px) {
        justify-content: center;
        .account {
          width: auto;
        }
      }
    }
  }
  .hashtags {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    @media (min-width: 1200px) {
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;
    }
    .add {
      width: 35px;
      height: 35px;
      border-radius: $input_radius * 2;
      margin-top: 8px;
      svg {
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        fill: $administration_hashtag_supprimer_couleur;
      }
      @media (min-width: 1200px) {
        margin-left: 10px;
      }
    }
    .input-container {
      width: 100%;
      @media (min-width: 1200px) {
        width: 260px;
        max-width: 100%;
        margin: 0 10px;
      }
      span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 30px;
        background: $administration_hashtag_fond;
        color: $administration_hashtag_couleur;
        margin: auto;
        border-top-left-radius: $input_radius * 2;
        border-bottom-left-radius: $input_radius * 2;
      }
      input {
        padding-left: 40px;
        padding-right: 35px;
      }
      .reset {
        background: transparent;
        right: 5px;
        svg {
          fill: $administration_hashtag_supprimer_couleur;
        }
      }
      .suggestion-list {
        button {
          padding: 5px 35px 5px 40px;
        }
      }
    }
  }
}
