.popin-header {
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #dddddd;

    .popin-title {
        text-align: center;
        width: 100%;
        padding: 5px;
    }

    .alert {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;

        & svg {
            fill: currentColor;
            width: 32px;
            height: 33px;
        }
    }

}

.popin-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popin-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &.hrlike {
        border-bottom: 2px solid #dddddd;
        width: 80%
    }
}

.popin-email {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 3rem;
}

.greyed {
    color: #cccccc !important;
    font-weight: bolder
}

.darked {
    color: black !important;
    font-weight: bolder
}

@keyframes fade-in {
    0% {
        animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}