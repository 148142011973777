.popup {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 1002 !important;
  background: $popup_fond;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  transform: scale(2);
  transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
  .container {
    padding: 25px 20px;
    background: $popup_descriptif_fond;
    border-radius: $input_radius;
    overflow: hidden;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    .titre {
      text-transform: uppercase;
      text-align: center;
      font-size: 16px;
      letter-spacing: 1.1px;
      padding: 15px 0;
    }
    &:not(.titre) {
      font-size: 13px;
      line-height: 16px;
      overflow: auto;
    }
    form {
      width: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      textarea {
        background: #fff;
      }
      button {
        margin-top: 10px;
      }
    }
  }
  button,
  a {
    margin: 10px;
  }
  > * {
    transform: scale(0);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s;
  }
  &.active {
    transform: scale(1);
    > * {
      transform: scale(1);
    }
  }
  @media (min-width: 700px) {
    .container {
      width: 600px;
    }
  }
  &.popup-likes-list {
    background: rgba(0, 0, 0, 0.7);
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    overflow: auto;
    .container {
      overflow: auto;
    }
    .close {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $life_popup_fermer_fond;
      svg {
        fill: $life_popup_fermer_couleur;
        width: 12px;
      }
    }
    .header {
      text-align: center;
      padding: 10px 0 20px;
      font-size: 18px;
      font-weight: 800;
      position: relative;
      &::after {
        position: absolute;
        bottom: 0;
        height: 1px;
        background: #bebebe;
        width: 100%;
        left: 0;
        right: 0;
        margin: auto;
        content: '';
      }
    }
    .list {
      width: 100%;
      padding: 40px 0;
      display: block;
      .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 0;
        img,
        svg {
          width: 47px;
          height: 47px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 15px;
        }
      }
      @media (min-width: 1200px) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        .item {
          width: 50%;
          padding: 10px;
        }
      }
    }
  }
}
