.common-circle {
  z-index: 1;
  position: relative;
  &::before,
  &::after {
    content: '';
    position: fixed;
    height: calc(100vw - 50px);
    width: calc(100vw - 50px);
    border-radius: 50%;
    z-index: -1;
    overflow: hidden;
    @media (min-width: 1200px) {
      height: 60vh;
      width: 60vh;
    }
  }
  &.common-connexion {
    &.left::before,
    &.right::after {
      border: solid 130px $connexion_cercle;
    }
  }
  &.common-private {
    &.left::before,
    &.right::after {
      border: solid 130px $prive_cercle;
    }
  }
  &.common-administration {
    &.left::before,
    &.right::after {
      border: solid 130px $administration_cercle;
      opacity: 0.15;
    }
  }
  &.center {
    &::before {
      border: solid 130px $popup_cercle_milieu;
    }
  }
  &.right {
    &::after {
      top: 0;
      right: 0;
      transform: translateX(50%);
    }
  }
  &.left {
    &::before {
      bottom: -20px;
      left: 0;
      transform: translateX(-50%);
    }
  }
  &.left.right {
    &::before {
      transform: translate(-60%, 60%);
      @media (min-width: 1200px) {
        transform: translate(-200px, 30%);
      }
    }
    &::after {
      transform: translate(55%, -10%);
      @media (min-width: 1200px) {
        transform: translateY(-20%);
      }
    }
  }
}
