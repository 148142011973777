// NOUVELLE NOMENCLATURE COULEURS

// Pages privées
$prive_cercle: var(--grey-extrem-lighter);
$prive_fond_pages: var(--blank-color);

// Pages de connexion / inscription
$connexion_couleur_titre: var(--primary-color);
$connexion_fond_page: var(--blank-color);
$connexion_cercle: rgba(var(--secondary-color), 0.05);
$connexion_header_fond: linear-gradient(to bottom, var(--primary-color) -25%, var(--primary-color) 125%);
$connexion_header_shadow: none;
$connexion_premiere_bouton_fond: var(--secondary-color);
$connexion_premiere_bouton_text:var(--blank-color);
$connexion_desktop_logo_height :55px;
//Privacy policy
$privacy_header_fond:linear-gradient(to bottom, var(--primary-color) -25%, var(--primary-color) 125%);
$privacy_policy_arrow_back:var(--blank-color);

// Boutons
$bouton_couleur_texte: var(--ui-text-primary-color);
$bouton_secondary_couleur_texte:var(--blank-color);
$bouton_contour: 23.5px;
$bouton_principal_fond: linear-gradient(to top, var(--primary-color), var(--primary-color));
$bouton_principal_lighter_fond: linear-gradient(to top, var(--primary-lighter), var(--primary-lighter));
$bouton_secondaire_fond: var(--grey-lighter);
$bouton_ternaire_couleur: var(--primary-color);

// TODO
$lien_couleur_defaut: var(--blank-color);

// Commun
$fleche_retour_couleur: var(--blank-color);
$input_radius: 5px;
$desktop_petit_cercle_couleur: var(--grey-color);
$desktop_petit_cercle_opacite: 0.15;
$desktop_grand_cercle_couleur: var(--grey-very-lighter);
$desktop_grand_cercle_opacite: 0.35;

// Popup
$popup_fond: var(--blank-color);
$popup_descriptif_fond: var(--grey-very-lighter);
$popup_cercle_milieu: rgba(var(--grey-extrem-lighter), 0.05);

// Menu
$menu_mobile_fond: transparent;
$menu_desktop_fond: var(--blank-color);
$menu_desktop_logo_height: 80px;
$menu_desktop_titre_fond_haut: var(--primary-color);
$menu_mobile_titre_fond: linear-gradient(to top, var(--primary-color), var(--primary-color));
$menu_mobile_titre_couleur: var(--ui-text-primary-color);
$menu_favori_inactif: var(--blank-color);
$menu_nouveau_post_contour: var(--primary-color);
$menu_nouveau_post_couleur: var(--primary-color);
$menu_desktop_nouveau_post_contour: var(--primary-color);
$menu_desktop_nouveau_post_couleur: var(--primary-color);
$menu_favori_actif: var(--blank-color);
$menu_fermer_burger: var(--blank-color);
$menu_burger_contour: var(--blank-color);
$menu_burger_couleur: var(--primary-color);
$menu_burger_icone_valider_recherche: var(--primary-color);
$menu_burger_liens_couleur_texte: var(--blank-color);
$menu_burger_liens_couleur_fond: linear-gradient(to bottom, var(--primary-color), var(--primary-color));
$menu_background:linear-gradient(to bottom, var(--primary-color), var(--primary-color));
$menu_burger_liens_couleur_chevrons: var(--blank-color);
$menu_burger_liens_couleur_rubriques: var(--secondary-color);
$menu_burger_liens_couleur_bordures_rubriques:  var(--secondary-color);
$menu_burger_fond_header: var(--primary-color);
$menu_burger_titre_fond_haut: var(--blank-color);
$menu_burger_titre_fond_bas: var(--blank-color);
$menu_burger_titre_couleur: var(--black-color);
$menu_reseaux_fond_header: linear-gradient(to top, var(--primary-lighter), var(--primary-lighter));
$menu_reseaux_fond_container: linear-gradient(to top, var(--primary-lighter), var(--primary-lighter));
$menu_reseaux_icone_couleur: var(--primary-color);

// Calendrier
$calendrier_fond_page: var(--blank-color);
$calendrier_mois_couleur_inactif: var(--grey-lighter);
$calendrier_mois_couleur_actif: var(--primary-color);
$calendrier_jour: var(--grey-lighter);
$calendrier_date: var(--grey-darker);
$calendrier_date_active_couleur: var(--blank-color);
$calendrier_date_active_fond: var(--primary-color);

// Liste des évènements dans la page Agenda
$liste_evenements_mois_fond: var(--grey-very-lighter);
$liste_evenements_mois_couleur: var(--grey-color);
$liste_evenements_evenement_fond: var(--blank-color);
$liste_evenements_evenement_date_fond: var(--primary-color);
$liste_evenements_evenement_date_couleur: var(--ui-text-primary-color);
$liste_evenements_evenement_description_couleur: var(--grey-darker);
$liste_evenements_evenement_invitation_message: var(--primary-lighter);
$liste_evenements_evenement_invitation_boutons_contour: var(--primary-color);
$liste_evenements_evenement_invitation_boutons_couleur: var(--primary-color);
$liste_evenements_evenement_options_couleur: var(--grey-color);
$liste_evenements_evenement_options_fond: var(--grey-very-lighter);
$liste_evenements_evenement_options_option_couleur: var(--grey-color);
$liste_evenements_evenement_options_option_checkbox_fond: var(--grey-very-lighter);
$liste_evenements_evenement_options_option_checkbox_couleur_svg: var(--black-color);
$liste_evenements_evenement_options_option_checkbox_couleur_inactif: var(--alert-color);
$liste_evenements_evenement_options_option_checkbox_couleur_actif: var(--secondary-color);

// ShortLinks (home + page photos)
$shortlinks_lien_inactif: var(--grey-color);
$shortlinks_lien_actif: var(--primary-lighter);

// Accueil
$accueil_article_fond: var(--blank-color);
$accueil_article_fleche_couleur: var(--ui-secondary-color);
$accueil_boutique_article_fleche_couleur: var(--ui-text-secondary-color);
$accueil_article_fleche_fond: var(--primary-color);
$accueil_article_texte_couleur: var(--black-color);
$accueil_article_bar_admin_icone_fond: var(--grey-extrem-lighter);
$accueil_article_bar_admin_icone_couleur: var(--grey-darker);
$accueil_article_important_texte_couleur: var(--blank-color);

$accueil_text-like:var(--primary-color);



// Barre de recherche
$searchbar_fond: var(--primary-color);
$searchbar_inactive_couleur: var(--blank-color);
$searchbar_active_couleur: var(--black-color);
$searchbar_annuler_couleur: var(--blank-color);
$searchbar_couleur_simulateur_micro: var(--primary-color);

// Annuaires
$annuaire_fond_page: var(--blank-color);
$annuaire_shortlinks_lien_inactif: var(--grey-color);
$annuaire_shortlinks_lien_actif: var(--black-color);
$annuaire_alphabet_couleur: var(--grey-color);
$annuaire_lettre_couleur: var(--black-color);
$annuaire_contact_couleur: var(--black-color);
$annuaire_favoris_icone: var(--secondary-color);
$annuaire_ligne_separateur: var(--grey-lighter);
$annuaire_popup_nom_couleur: var(--blank-color);
$annuaire_popup_icone_secondaire_fond: linear-gradient(168deg, var(--grey-very-lighter) -1%, var(--grey-very-lighter) 102%);
$annuaire_popup_icone_secondaire_couleur: var(--primary-color);
$annuaire_popup_icone_principale_fond: var(--primary-lighter);
$annuaire_popup_icone_principale_couleur: var(--blank-color);
$annuaire_desktop_raccourcis_icones: var(--blank-color);
$annuaire_filtre_actif_fond: linear-gradient(to bottom, var(--primary-color), var(--primary-color));

// Life
$life_popup_filtres_couleur: var(--blank-color);
$life_popup_filtres_fond: var(--primary-color);
$life_popup_fermer_couleur: var(--blank-color);
$life_popup_fermer_fond: var(--primary-color);

// Article
$article_fond_page: var(--grey-extrem-lighter);
$article_cercle: var(--grey-very-lighter);
$article_texte_couleur: var(--black-color);
$article_commentaire_couleur: var(--black-color);
$article_icone_retour: var(--primary-color);
$article_sticky_likes_couleur: var(--blank-color);
$article_sticky_likes_fond: var(--primary-color);
$article_formulaire_fond: var(--blank-color);
$article_likes_commentaires_couleur: var(--black-color);
$article_galerie_fleches_fond: var(--secondary-color);
$article_galerie_fleches_color:var(--blank-color);
$article_hashtag_fond: var(--primary-color);

// Profil
$profil_cercle: linear-gradient(to bottom, var(--primary-color), var(--primary-color) 102%);
$profil_information_fond: var(--blank-color);
$profil_information_couleur: var(--grey-darker);
$profil_information_annuler: var(--alert-color);
$profil_information_valider: var(--secondary-color);
$profil_burger_couleur: var(--secondary-color);
$profil_cgu_icone_couleur: var(--blank-color);
$profil_cgu_icone_fond: var(--primary-color);
$profil_cgu_couleur: var(--blank-color);
$profil_cgu_fond: var(--primary-color);
$profil_edition_photo_fond: var(--blank-color);
$profil_edition_photo_couleur: var(--primary-color);
$profil_edition_photo_ombre:none;
$profil_snooze_header_fond: var(--grey-darker);
$profil_snooze_header_couleur: var(--blank-color);
$profil_sooze_icone_inactive_fond: var(--secondary-color);
$profil_sooze_icone_inactive_couleur: var(--blank-color);
$profil_sooze_icone_active_fond: var(--grey-color);
$profil_sooze_icone_active_couleur: var(--blank-color);
$profil_snooze_fond: var(--grey-color);
$profil_snooze_couleur: var(--blank-color);
$profil_offline_icone_fond: var(--alert-color);
$profil_offline_icone_couleur: var(--blank-color);
$profil_online_icone_fond: var(--secondary-color);
$profil_online_icone_couleur: var(--blank-color);
$profil_background_badge_pencil: var(--blank-color);

// Administration
$administration_menu_desktop_titre_fond_haut: var(--primary-color);
$administration_cercle: var(--grey-lighter);
$administration_fond_page: var(--blank-color);
$administration_menu_titre_fond: linear-gradient(to top,  var(--primary-color), var(--primary-color));
$administration_menu_titre_couleur: var(--blank-color);
$administration_menu_burger_couleur: var(--blank-color);
$administration_menu_annuler_fond: var(--black-color);
$administration_menu_annuler_couleur: var(--blank-color);
$administration_bloc_fond: var(--grey-very-lighter);
$administration_etapes_icone_fond: var(--primary-color);
$administration_etapes_icone_couleur: var(--blank-color);
$administration_etapes_header_item_inactif_couleur: var(--grey-color);
$administration_etapes_header_item_actif_couleur: var(--blank-color);
$administration_etapes_header_item_actif_fond: var(--primary-color);
$administration_etapes_categorie_icone_fond_inactif: var(--grey-color);
$administration_etapes_categorie_icone_fond_actif: var(--primary-color);
$administration_etapes_categorie_icone_couleur: var(--blank-color);
$administration_label_couleur: var(--black-color);
$administration_etapes_indicateur_fond_inactif: var(--grey-color);
$administration_etapes_indicateur_fond_actif: var(--primary-color);
$administration_input_fond: var(--blank-color);
$administration_input_datepicker_fond_actif: var(--primary-color);
$administration_input_datepicker_couleur_actif: var(--blank-color);
$administration_input_import_fichier_fond: var(--grey-very-lighter);
$administration_input_import_fichier_bordure: var(--primary-color);
$administration_input_import_fichier_couleur: var(--primary-color);
$administration_input_email_contour: var(--grey-color);
$administration_input_email_actif: var(--grey-color);
$administration_destinataires_label_couleur: var(--black-color);
$administration_destinataires_accordeon_fond: var(--primary-color);
$administration_destinataires_accordeon_couleur: var(--blank-color);
$administration_destinataires_checkbox_contour: var(--blank-color);
$administration_destinataires_checkbox_active: var(--blank-color);
$administration_hashtag_fond: linear-gradient(to bottom, var(--primary-color), var(--primary-color));
$administration_hashtag_couleur: var(--blank-color);
$administration_hashtag_supprimer_couleur: var(--primary-color);
$administration_suggestion_fond: linear-gradient(to bottom, var(--primary-color), var(--primary-color));
$administration_suggestion_couleur: var(--blank-color);
$administration_reseaux_sociaux_actif_fond: linear-gradient(to bottom, var(--primary-color), var(--primary-color));
$administration_reseaux_sociaux_actif_couleur: var(--blank-color);

// Commun formulaires
$input_couleur: var(--black-color);
$input_fond: var(--grey-very-lighter);

// Loader
// TODO
$loader_darken_color: var(--primary-color);

// Tutoriel d'installation
$tuto_background: linear-gradient(to top, var(--primary-color) 10%, var(--primary-color) 75%);
$tuto_text_color: var(--blank-color);
$tuto_footer_background: var(--primary-color);

// Sondages
$sondage_header_fond: var(--grey-very-lighter);
$sondage_date_inactive_fond: var(--primary-color);
$sondage_date_inactive_couleur: var(--ui-text-primary-color);
$sondage_date_active_fond: var(--secondary-color);
$sondage_date_active_couleur: var(--ui-text-secondary-color);
$sondage_bloc_fond: var(--blank-color);
$sondage_bloc_couleur: var(--grey-darker);
$sondage_bloc_actif_couleur: var(--ui-primary-color);
$sondage_bloc_votes_couleur: var(--grey-lighter);
$sondage_bloc_total_couleur: var(--grey-color);
$sondage_filtre_actif_fond: var(--primary-color);
$sondage_filtre_titre_couleur: var(--primary-color);
$sondage_filtre_choix_couleur: var(--primary-color);

// TODO
$badge_fond: var(--alert-color);
// TODO
$badge_couleur: var(--blank-color);

// TODO
$badge_nouveaute_fond: var(--alert-color);
// TODO
$badge_nouveaute_couleur: var(--blank-color);

$aucun_contenu_couleur: var(--primary-color);

// Partage sur les réseaux sociaux
$liste_hashtags_fond: var(--grey-color);
$article_choix_reseau_bordure: var(--primary-color);
$article_choix_reseau_fond: linear-gradient(to top, var(--primary-color), var(--primary-color));

// Boutique - HEADER
$boutique_header_desktop_fond_degrade: var(--primary-color);
$boutique_header_titre_rubrique_fond_haut: var(--primary-color);
$boutique_header_titre_rubrique_fond_bas: var(--primary-color);
$boutique_sticky_maison_desktop_couleur: var(--blank-color);
$boutique_sticky_maison_mobile_couleur: var(--primary-color);

// Boutique - SEARCHBAR
$boutique_searchbar_couleur_principale: var(--blank-color);
$boutique_searchbar_couleur_secondaire: var(--blank-color);
$boutique_searchbar_couleur_textes: var(--black-color);

// Boutique - POPUP
$boutique_popup_fond: linear-gradient(to bottom, var(--ui-primary-color) 0%, var(--ui-primary-color) 100%);
$boutique_popup_cercles_fond: rgba(249, 249, 249, 0.1);
$boutique_popup_header_fond: var(--blank-color);
$boutique_popup_header_ombre:none;
$boutique_popup_icones_couleur: var(--ui-primary-color);
$boutique_popup_texte_couleur: var(--ui-primary-color);

// Boutique - BOUTONS
$boutique_bouton_principal_fond: linear-gradient(to top, var(--primary-color), var(--primary-color));
$boutique_bouton_secondaire_fond: var(--secondary-darker);

// Boutique - SELECTEUR QUANTITE
$boutique_selecteur_quantite_couleur_foncee: var(--ui-primary-color);
$boutique_selecteur_quantite_couleur_claire: var(--grey-very-lighter);

// Boutique - STICKY ACCES BOUTIQUE / PANIER
$boutique_sticky_acces_boutique_fond: var(--secondary-color);
$boutique_sticky_acces_panier_fond: var(--secondary-color);
$boutique_sticky_acces_icone_couleur: var(--ui-text-secondary-color);
$boutique_sticky_acces_icone_couleur_outer: var(--ui-text-secondary-color);
$boutique_sticky_acces_badge_couleur: var(--blank-color);
$boutique_sticky_acces_badge_fond: var(--alert-color);

// Boutique - LISTE DES PRODUITS
// $boutique_filtres_couleur_principale: #0e6157;
// $boutique_filtres_couleur_secondaire: #1addc6;
$boutique_preview_produit_couleur_principale: var(--black-color);
$boutique_preview_produit_couleur_secondaire: var(--secondary-color);
$boutique_preview_produit_couleur_alerte: var(--alert-color);
$boutique_preview_produit_couleur_alerte_indisponible: var(--grey-color);
$boutique_preview_produit_couleur_prix_raye: var(--grey-color);

// Boutique - PAGE PRODUIT

$boutique_produit_icone_retour_couleur: var(--black-color);
$boutique_produit_sticky_tarif_raye_fond: var(--grey-color);
$boutique_produit_sticky_tarif_fond: var(--secondary-color);
$boutique_produit_sticky_tarif_couleur: var(--ui-text-secondary-color);
$boutique_produit_sticky_tarif_promo_fond: var(--alert-color);
$boutique_produit_sticky_tarif_promo_couleur: var(--blank-color);
$boutique_produit_liens_fond: var(--primary-color);
$boutique_produit_compte_a_rebours_couleur: var(--black-color);
$boutique_produit_avertissement_couleur: var(--black-color);
$boutique_produit_stock_couleur: var(--blank-color);
$boutique_produit_stock_fond: var(--alert-color);
$boutique_produit_prix_fond: var(--alert-color);
$boutique_produit_prix_couleur: var(--blank-color);
$boutique_produit_personnaliser_fond: var(--blank-color);
$boutique_produit_personnaliser_couleur: var(--primary-color);
$boutique_produit_ajout_panier_fond: var(--secondary-color);
$boutique_produit_ajout_panier_couleur: var(--ui-text-secondary-color);
$boutique_produit_personnalisation_produit_couleur_principale: var(--ui-primary-color);
$boutique_produit_color_total_line:var(--ui-primary-color);
$boutique_produit_color_total:var(--ui-primary-color);
$boutique_produit_personnalisation_participants_couleur_principale: var(--primary-color);

// Boutique - PRIX DEGRESSIFS
$boutique_prix_deg_fond_filtre: #cceffb;
$boutique_prix_deg_couleur_1: var(--secondary-color);
$boutique_prix_deg_couleur_2: var(--primary-lighter);
$boutique_prix_deg_couleur_3: var(--primary-color);

// Boutique - PANIER
$boutique_panier_titre_couleur: var(--black-color);
$boutique_panier_promo_valider_fond: var(--secondary-color);
$boutique_panier_promo_valider_couleur: var(--ui-text-secondary-color);
$boutique_panier_promo_message_couleur: var(--alert-color);
$boutique_panier_produit_suppression_fond: var(--alert-color);
$boutique_panier_produit_suppression_couleur: var(--blank-color);
$boutique_panier_service_suppression_couleur: var(--alert-color);
$boutique_panier_validation_fond: var(--secondary-color);
$boutique_panier_validation_couleur: var(--blank-color);


$ca_color_1:var(--secondary-color);
$ca_color_2:var(--primary-lighter);
$ca_color_3:var(--primary-color);



//JEUX DE COLOR POUR MONDIAL // DEBUT
$color_primary:var(--primary-color);
$color_secondary:var(--primary-lighter);
$color_thirty:var(--secondary-color);
$color_fourth:var(--alert-color);
.wrapper-card{


  cursor:pointer;
  position: relative;
  border-radius: 0.4rem;
  outline: none;
  padding-top:var(--padding-top-card);
  //#F7F7F7
  //box-shadow:var(--box-shadow-card);
  background-color:white;
  margin-top: 20px;
  box-sizing: border-box;
  //transform:var(--scale-card);
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
  &:hover{
      box-shadow: 0 10px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);
  }

}

.header-card{
  position: relative;
}

.header-card-post{
  height: 2rem;
  padding:0rem 1rem;
  display: flex;
  align-items: center;
  position: relative;
}


.date-container{
    height: 1rem;
    box-sizing: border-box;
    font-size: 0.8rem;
    color:var(--grey-color);

    user-select: none;
    display: flex;
    align-items: center;
   
    gap:0.2rem;
    &span{
        
    }:first-letter{
        text-transform: capitalize;
    }
    svg{
        width:0.8rem;
        height:0.8rem;
        fill:var(--grey-color);
    }
}


.option-post-card{
  position: absolute;
  right:1rem;
  top:0rem;
  
  
  .option-button{
      background:none;
      border:none;
      cursor: pointer;
      &:hover{
          svg{
          fill:var(--grey-lighter);
          }
      }
     
      svg{
          width:1.8rem;
          height: 1.8rem;
          fill:var(--grey-color)
      }

}
}


.image-container{
  overflow: hidden;
  img{
    position: relative;
    //border-radius: var(--border-radius-img);
    display: block;
    width:100%;
    height:100%;
    object-fit: cover;
    filter:var(--brightness-image);
    object-position: center center;
    //transform:var(--scale-card);

  }
}


.author-container{
  font-size: 0.7rem;
      color:rgb(56, 56, 56);
      margin-top:0.5rem;
      padding:0rem 1rem;
      user-select: none;
      display: flex;
      align-items: flex-start;
      gap:0.2rem;
      svg{
          width:0.8rem;
          height:0.8rem;
          fill:grey;
      }

   .author{
      
      text-transform: capitalize;
      &:hover{
         
          font-weight: 600;
          
      }   
   }   
  .entreprise{
      margin-left: 0.2rem;
      &:hover{
        
          font-weight: 600;
      }
  }
}

.title-container{
  padding:1rem 1rem 0rem 1rem; 
  font-weight: 800;
  text-transform: uppercase;

}


.banner-rs{
  margin-top: 1rem;
  background:var(--background-banner-rs);
  height: 2rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  display: flex;
  align-items: center;
  padding:1rem;
  gap:1rem;

}


.rs-identity{
  display: inline-flex;
  align-items: center;
  height: 100%;
  gap:0.5rem;
  flex:1;
}
.container-icon{
  width:0.9rem;
  height: 0.9rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg{
    fill:#FFFFFF;
  }
}

.name-rs{
  color:#FFFFFF;
  font-size: 0.8rem;
}

.arrow{
 
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg{
    fill:#FFFFFF;
    width:1.2rem;
  }

}

.moderation{
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  height:2rem;
  background-color: var(--alert-color);
  line-height: 2rem;
  box-sizing: border-box;
  color:white;
  text-align: center;
  font-weight: 600;
}

.tag-container{
  display: flex;
  gap:0.5rem;
  color:#FFFFFF;
  padding:0.5rem 1rem 0rem 1rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  flex-wrap: wrap;
  .tag{
    border-radius: 0.4rem;
   color:var(--grey-light-darker)
  }
}