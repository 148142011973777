@media (min-width: 1200px) {
  .common-leads #header .navbar-top .tab {
    left: 0;
    width: 100%;
    background: #f7f7f7;
  }
}

.common-leads {
  background: #f7f7f7;
}