.template-connexion {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(250px + #{$connexion_desktop_logo_height - 55px});
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      box-shadow: 0 2px 34px 0 $connexion_header_shadow;
      background: $connexion_header_fond;

      @media (min-width: 1200px) {
        border-bottom-left-radius: 90px;
        border-bottom-right-radius: 90px;
      }
    }

    .btn-second {
      margin-top: 50px;

      &.first-connexion {
        position: relative;
        z-index: 1;
        background: $connexion_premiere_bouton_fond;
        color:$connexion_premiere_bouton_text !important;
      }
    }

    form {
      padding: 90px 0;
    }
  }

  .installation {
    justify-content: flex-start;
    padding: 0 0 50px;
    overflow: scroll;

    * {
      color: $tuto_text_color;
    }

    .tuto-container {
      background: $tuto_background;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .client-name {
      padding: 25px 30px 0;
      font-size: 13px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      font-weight: normal;
    }

    .title {
      padding: 20px 30px;
      font-size: 20px;
      font-weight: 800;
      letter-spacing: 0.4px;
      text-align: center;
      padding: 20px 0;
      line-height: 1.1;
    }

    .steps {
      padding: 30px 30px 0;

      .step {
        display: flex;
        margin: 25px 0;
        position: relative;

        .number {
          width: 30px;
          font-size: 35px;
          padding-right: 10px;
          text-align: right;
          border-right: solid 1px $tuto_text_color;
          line-height: 0.5;
        }

        .content {
          width: calc(100% - 30px);
          padding-left: 10px;
          font-size: 14px;
          line-height: 1.3;
          position: relative;
          padding-right: 40px;
        }

        &.last {
          padding-bottom: 50px;

          svg {
            width: 38px;
            height: 38px;
            position: absolute;
            top: calc(100% - 10px);
            right: 0;
          }
        }
      }

      &.safari,
      &.chrome,
      &.firefox,
      &.internet {
        .one {
          svg {
            position: absolute;
            width: 38px;
            height: 38px;
            right: 0;
            bottom: 100%;
          }
        }

        .two {
          padding-bottom: 30px;

          svg {
            width: 175px;
            height: 40px;
            position: absolute;
            top: calc(100% - 25px);
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .actions {
      width: 100%;
      background: #fff;
      display: flex;
      justify-content: space-between;
      height: 50px;
      position: relative;

      &::before {
        width: calc(100% - 160px);
        left: 80px;
        content: '';
        position: absolute;
        top: 0;
        background: $tuto_footer_background;
        height: 50px;
      }

      >div {
        width: 80px;
      }

      .bar-right {
        transform: rotateY(180deg);
      }

      button {
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }
  }

  .footer {
    font-style: normal !important;
    font-size: 14px !important;
  }
}