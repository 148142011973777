.common-administration {
  @media (min-width: 700px) and (max-width: 1200px) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    margin-top: 115px !important;
    padding-top: 75px !important;
    padding-left: 0;
    padding-right: 0;
  }
  #header {
    .navbar-top {
      .tab::before {
        background: $administration_menu_desktop_titre_fond_haut;
      }
    }
  }
}
