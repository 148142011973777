.privatePolicyContainer {
  padding: 0px 10vw;
  font: Raleway;
  .titleContainer {
    padding: 0px 40px;
    width: 100%;
    height: 150px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: 0 2px 34px 0 $connexion_header_shadow;
    background: var(--primary-color);

    .back-arrow-container{
      svg{
        fill:#FFF;
      }
    }
    @media (min-width: 1200px) {
      border-bottom-left-radius: 90px;
      border-bottom-right-radius: 90px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h1 {
    padding: 32px 0px;
    font-size: 2rem;
    line-height: 2rem;
    flex-grow: 4;
    text-align: center;
    color: #ffffff;
  }

  h2 {
    padding: 24px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: #191127;
  }
  h3 {
    padding: 16px;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: #191127;
  }
  p {
    padding: 8px;
  }

  .contentContainer {
    padding: 32px 16px;
  }
}
