/*.template-ca {
  h2 {
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    padding-top: 20px;
    padding-bottom: 20px;
    color:$ca_color_2;
  }
  #profile-informations {
    transform: translateY(0);
    padding-top: 20px;
    flex-direction: row;
    > div {
      padding: 10px;
    }
    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      button {
        height: 38px;
      }
    }
    @media (max-width: 820px) {
      > * {
        flex: 100%;
        max-width: 100%;
      }
      > div {
        padding: 0;
      }
      .submit {
        padding-top: 10px;
      }
    }
  }
  .decreasing-prices {
    @media (min-width: 1200px) {
      background: none;
    }
    .container-array {
      padding-left: 10px;
      padding-right: 10px;
      margin: auto;
      max-height: none;
      padding-top: 20px;
      @media (max-width: 820px) {
        padding-left: 0;
        padding-right: 0;
      }
      .array{



        .header{
            display: flex;

            .cell-header-mb{
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              color: #fff;
              text-transform: uppercase;
              font-size: 12px;
              padding: 5px;
              width:calc(100% /4);
              margin-left:3px;
              
            }    
       
          .cell-header-dk{
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            padding: 5px;
            width:calc(100% / 9);
            margin-left:3px;
            
          }
          :first-child{
            margin-left: 0;
            border-top-left-radius: $input-radius * 2; 
            border-bottom-left-radius: $input-radius * 2;
          }
          :last-child{
            border-top-right-radius: $input-radius * 2;
            border-bottom-right-radius:$input-radius *2
          }
          :nth-child(3n+1) {
            background: $ca_color_1;
            
          }
          :nth-child(3n+2) {
            background: $ca_color_2;
          }
          :nth-child(3n+3) {
            background: $boutique_prix_deg_couleur_3;
            
          }
        
        }
        .editores{
          position: absolute;
          right:-100px;
          display: flex;
         
          align-items: center;
        }
        .line{

.container-line{
  height:30px;
  width:calc(100% / 9);
  right:-100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position:absolute;
  z-index: 2;
  
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;


  .reset,
  .submit {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 10px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
  .reset {
    background: $profil_information_annuler;
    margin-right: 8px;
  }
  .submit {
    background: $profil_information_valider;
  }
  svg {
    fill: $profil_information_couleur;
    width: 15px;
    height: 15px;
  }
}
          display: flex;
           input{
            
            width:calc(100% / 9);
            border:0;outline:0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          :focus{outline:none!important}
          .cell-array-mb{
            height:30px;
            width:calc(100% / 4);
            margin-left:3px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .cell-array-dk{
            height:30px;
            width:calc(100% / 9);
            margin-left:3px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
         
          :first-child{
            margin-left:0
          }
          :nth-child(3n+1) {
            color: $ca_color_1;
            
          }
          :nth-child(3n+2) {
            color: $ca_color_2;
          }
          :nth-child(3n+3) {
            color: $boutique_prix_deg_couleur_3;
            
          }
        }
      }
    



    }
  }
}*/


////////////////////  TABLE CA    //////////////////////////////


// Title and line


.ca-page-container{
  padding-top: 100px;
  width:calc(100vw - 320px);

  margin-left:320px;
  @media screen and (max-width:1200px) {
    margin-left: 0px;
    width:100vw
  }
  @media screen and (min-width:1200px) {
    padding-top: 200px;
    
  }

.title-ca{
    
    color:$ca_color_2;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    padding-top:20px;
    padding-bottom: 20px;

}

.container-draw{
  height:2px;
  max-width: 600px;
  margin:0 auto;

  @media screen and (max-width:600px) {
    padding-left: 20px;
    padding-right: 20px;
    
  }

  .draw{
    background-color:$ca_color_2;
    width:100%;
    height:2px
  }
}

.subdraw{
  color:$ca_color_2;
  font-size:14px;
  font-weight:400;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px; 
  box-sizing: border-box;
  @media screen and (max-width:600px) {
    padding-left: 20px;
    padding-right: 20px;
    
  }
}
.prevent-last-month{
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 40px;
  text-align: center;
  h3{
    color:red
  }
}
.form-ca-input{
  display: flex;
  width:100%;
  justify-content: space-around;
  
  width:calc(100% - 200px );
  margin:40px auto 0px auto;
  @media screen and (max-width:600px) {
    width:calc(100% - 120px );
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}

//Input Label




.form{
  .label-ca-input{
  font-size:1em;
  margin-left: 10px;
  font-weight: 600;
  }
  .label-ca-input + input{
    margin-top: 10px;
    margin-bottom:20px;
  }
}
.form-ca{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

.container-submit-ca{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  }

}


//CSS TABLECA

.ca-tabs-container{
  
  width:calc(100% - 200px );
  margin:40px auto 100px auto;
  @media screen and (max-width:600px) {
    width:calc(100% - 60px );
    margin:100px auto 100px auto;
  }



.ca-tabs-header{
  display: flex;
  height: 40px;




.ca-tabs-cell-header{
  flex:1;
  overflow: hidden;
  flex-wrap:nowrap;
  margin-left:3px;
  margin-right:3px;
  box-sizing: border-box;
  padding-left:10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  text-align: center;

}
.ca-tabs-cell-header.cellmb{

  @media screen and (max-width:1200px){
    font-size: 18px;
    &.long-text{
      font-size: 14px;
    }
  }
  @media screen and (max-width:800px) {
    font-size: 15px;
    &.long-text{
      font-size: 12px;
    }
  }
  @media screen and (max-width:500px) {
    font-size: 10px;
    &.long-text{
      font-size: 8px;
    }
  }
  @media screen and (max-width:300px) {
    font-size: 8px;
    &.long-text{
      font-size: 8px;
    }
  }
  &:first-child{
    @media screen and (max-width:600px) {
      flex:0.5;
    }
    margin-left: 0;
    border-top-left-radius: $input-radius * 2; 
    border-bottom-left-radius: $input-radius * 2;
  }
  &:last-child{
    border-top-right-radius: $input-radius * 2;
    border-bottom-right-radius:$input-radius *2
  }
  &:nth-child(1) {
    background: $ca_color_1;
  
    
  }
  &:nth-child(1n+2){
    background-color:$ca_color_2 ;
  }
}



.ca-tabs-cell-header.celldk{
  font-size: 10px;
  &:first-child{
    
    border-top-left-radius: $input-radius * 2; 
    border-bottom-left-radius: $input-radius * 2;
  }
  &:last-child{
    border-top-right-radius: $input-radius * 2;
    border-bottom-right-radius:$input-radius *2
  }
  &:nth-child(1) {
    background: $ca_color_1;
    color:var(--ui-text-secondary-color);
    font-weight: 600;
    
  }
  &:nth-child(4n+5) {
    background: $ca_color_2;
    
    
  }
  &:nth-child(4n+2) {
    background: $ca_color_2;
  }
  &:nth-child(4n+3) {
    background: $ca_color_2;
  }
  &:nth-child(4n+4) {
    background: $ca_color_3;
  }
  
}



}

//Line Table


.container-action-icon{
  display: flex;
  justify-content: center;
  align-items: center;

  .button-icon{
    height:40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    .container-icon{
      width:20px;
      height: 20px;
      border-radius:50%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
        width:10px;
        height:10px;
        fill:white
      }
    }
    .container-icon.submit{
      background: $profil_information_valider;
    }

    .container-icon.reset{
      background:$profil_information_annuler;
    }

  }
}





.ca-tabs-line{
  box-sizing: border-box;
  position: relative;
  padding-top:5px;
  padding-bottom: 5px;
  display: flex;
  height: 40px;

  &.odd{
    background-color:rgb(245, 245, 245);
}
&.even{
  background-color:white;

}






.edit-table{
  position:absolute;
  right:-50px;
  top:0px;
  background-color:transparent;
  height:40px;
  width:40px;
  display: flex;
  align-items: center;
  justify-content: center
  ;
  @media screen and (max-width:600px) {
    right:-30px
  }
  .container-icon{
    width:15px;
    height: 15px;
  }
}


.ca-tabs-cell-line{
  flex:1;
  overflow: hidden;
  flex-wrap:nowrap;
  margin-left:3px;
  margin-right:3px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;



  .ca-tabs-cell-input-line{
    width:100%;
    height:100%;
    border:none;
    outline:none;
    background-color:rgb(233, 233, 233);
  }
 
}
.ca-tabs-cell-line.cellmb{
 font-weight: 600;
  @media screen and (max-width:1200px) {
    font-size: 18px;
  }
  @media screen and (max-width:800px) {
    font-size: 15px;
  }
  @media screen and (max-width:500px) {
    font-size: 13px;
  }
  @media screen and (max-width:400px) {
    font-size: 11px;
  }

  @media screen and (max-width:300px) {
    font-size: 8px;
  }

  
  &:nth-child(1) {
    @media screen and (max-width:600px) {
      flex:0.5;
    }
  color: $ca_color_1;
  }
&:nth-child(n+2){
  
 color:$ca_color_2 ;
    }
  }

  .ca-tabs-cell-line.celldk{
    @media screen and (max-width:1300px) {
      font-size: 12px;
    }
    font-weight: 600;
    &:nth-child(1) {
      color:var(--ui-primary-color);
      
    }
    &:nth-child(4n+5) {
      color: $ca_color_2;
      
    }
    &:nth-child(4n+2) {
    
      color: $ca_color_2;
    }
    &:nth-child(4n+3) {
  
      color: $ca_color_2;
    }
    &:nth-child(4n+4) {
      
      color:$ca_color_3;
      }
    }

  }


  }
}