@media print {
    body * {
        visibility: hidden;
        zoom: 97%;
    }

    .section-to-print,
    .section-to-print * {
        visibility: visible;
    }

    .section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }

    .do-not-print {
        visibility: hidden;
        display: none;
    }
}