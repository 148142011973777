.template-shopping-product {
  @media (min-width: 1200px) {
    #header .navbar-top .tab {
      left: 0;
      width: 100%;
    }
    .dk-grid {
      transform: none !important;
    }
    .breadcrumb {
      padding-top: 40px;
    }
  }
  .link-back {
    @media (min-width: 1200px) {
      left: calc(50% - 465px);
    }
    svg {
      fill: $boutique_produit_icone_retour_couleur;
    }
    span {
      color: $boutique_produit_icone_retour_couleur;
    }
  }
  #shopping-bag {
    display: none;
    // @media (max-width: 1200px) {
    //   background: none;
    //   box-shadow: none;
    //   position: absolute;
    //   right: 20px;
    //   padding: 0;
    //   border-radius: 0;
    //   top: 17px;
    //   width: 25px;
    //   height: 25px;
    //   svg {
    //     fill: #0e6157;
    //     width: 25px;
    //     height: 25px;
    //     * {
    //       fill: #0e6157;
    //     }
    //   }
    // }
  }
  .visuel {
    .links {
      @media screen and (max-width:1200px) {
        bottom:20px;
      }
      left: 10px;
      right: auto;
      a {
        background: $boutique_produit_liens_fond;
        justify-content: center;
        height: 45px;
      }
      .tarif {
        margin: 0 10px;
        z-index: 3;
        // position: fixed;
        // right: 0;
        display: flex;
        align-items: center;
        color: $boutique_produit_sticky_tarif_couleur;
        background: $boutique_produit_sticky_tarif_fond;
        font-size: 20px;
        font-weight: bold;
        height: 45px;
        bottom: 50%;
        transform: translateY(50%);
        // transform: translateY(10px);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        padding: 10px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        // width: 75px;
        svg {
          fill: $boutique_produit_sticky_tarif_couleur;
          height: 16px;
          width: 16px;
          margin-right: 5px;
        }
        &.raye {
          background: $boutique_produit_sticky_tarif_raye_fond;
          text-decoration: line-through;
          color: #fff;
          ~ .tarif {
            background: $boutique_produit_sticky_tarif_promo_fond;
            color: $boutique_produit_sticky_tarif_promo_couleur;
          }
        }
      }
    }
  }
  .post {
    .container {
      .reference {
        font-style: italic;
        font-size: 12px;
        font-weight: 500;
      }
      .external-links {
        a {
          background: $boutique_produit_liens_fond;
        }
      }
      #countdown {
        padding: 10px;
        align-self: center;
        margin: 20px auto;
        width: 280px;

        div {
          width: 100%;
          display: flex;
          justify-content: space-around;
          > span {
            color: $boutique_produit_compte_a_rebours_couleur;
            width: 22%;
            text-align: center;
            font-size: 11px;
            box-shadow: 10px 0 12px -4px rgba(120, 120, 120, 0.4), -10px 0 6px -4px rgba(120, 120, 120, 0.4);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 500;
            background: transparent;
            position: relative;
            padding: 5px;
            &::before,
            &::after {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 5px;
              background: #f5f6f5;
              transform: translateY(-50%);
              content: '';
            }
            &::after {
              top: 100%;
            }
            span {
              font-size: 12px;
              color: $boutique_produit_compte_a_rebours_couleur;
            }
          }
        }
        > span {
          color: $boutique_produit_compte_a_rebours_couleur;
          text-align: center;
          font-size: 12px;
          padding-top: 12px;
          width: 100%;
          display: block;
        }
        @media (min-width: 1200px) {
          width: 300px;

          #tiles > span {
            font-size: 24px !important;
          }
        }
      }
      .limit {
        font-size: 14px;
        padding: 10px 0;
        strong,
        u {
          color: $boutique_produit_avertissement_couleur;
        }
        &--count {
          strong,
          u {
            color: #df172f;
          }
        }
      }
      .detail {
        display: flex;
        align-items: center;
        padding: 7px 0;
        .svg {
          width: 50px;
          display: flex;
          align-items: center;
          svg {
            width: 35px;
            height: 35px;
          }
        }
        p {
          width: calc(100% - 50px);
          font-weight: 600;
          font-size: 14px;
        }
      }
      .stock-price {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 20px;
        .stock {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50%;
          .inner {
            border-radius: $input-radius * 2;
            background: $boutique_produit_stock_fond;
            padding: 12px 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            > span {
              color: $boutique_produit_stock_couleur;
              font-size: 12px;

              &:nth-child(1) {
                font-size: 22px;
                margin-bottom: 10px;
                font-weight: 500;
                border-radius: 50%;
                width: 52px;
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $boutique_produit_stock_fond;
                position: relative;
                z-index: 1;
                &::before {
                  content: '';
                  width: 52px;
                  height: 52px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  border-radius: 50%;
                  z-index: -1;
                  background: $boutique_produit_stock_fond;
                }
                span {
                  width: 68px;
                  height: 68px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  border-radius: 50%;
                  z-index: -2;
                  transform: translate(-8px);
                  &.rotate {
                    transform: translate(-8px) rotateX(180deg);
                  }
                }
              }
            }
          }
        }
        .price {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 0 20px;
          .inner {
            display: flex;
            width: 100%;
            margin: 5px 0;
            align-items: center;
            justify-content: center;
            text-align: center;
            // min-width: 80px;
            // height: 80px;
            border-radius: $input-radius * 2;
            padding: 0 12px;
            background: $boutique_produit_prix_fond;
            justify-content: space-around;
            flex-wrap: wrap;
            .tarif {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin: 5px 0;
              width: 100%;
              @media (min-width: 700px) {
                width: 50%;
              }
              &.raye {
                text-decoration: line-through;
                span {
                  font-size: 16px;
                  &:nth-child(2) {
                    font-size: 12px;
                  }
                }
                ~ .tarif {
                  span {
                    font-weight: 600;
                    &:nth-child(2) {
                      font-weight: normal;
                    }
                  }
                }
              }
            }
            span {
              color: $boutique_produit_prix_couleur;
              font-size: 22px;
              font-weight: 500;
              padding: 0 5px;
              &:nth-child(2) {
                font-size: 12px;
                font-weight: normal;
              }
            }
          }
        }
      }
      .participants {
        padding-top: 20px;
        > p {
          margin-bottom: 15px;
        }
        > .add-to-bag {
          display: none;
        }
        .participant {
          position: relative;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          background: #fff;
          border-radius: $input-radius * 2;
          padding: 10px;
          opacity: 0;
          transform: scale(0);
          animation: participantAppear 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s forwards;
          max-height: 200px;
          max-width: 100%;
          button {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            background: #9b9b9b;
            width: 22px;
            height: 22px;
            transform: translate(40%, -40%);
            svg {
              width: 10px;
              height: 10px;
              fill: #000;
            }
          }
          .visuel {
            min-height: auto;
            width: 60px;
            display: flex;
            justify-content: flex-start;
            img,
            svg {
              width: 50px;
              height: 50px;
              object-fit: cover;
              overflow: hidden;
              border-radius: 50%;
              margin: 0;
            }
          }
          .infos {
            width: calc(100% - 60px);
            p {
              line-height: 1.2;
              &:first-child {
                font-weight: bold;
                padding-bottom: 5px;
              }
            }
          }
          &.inactive {
            transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s;
            max-height: 0;
            max-width: 0;
            padding: 0;
            margin: 0 !important;
            background: transparent;
            * {
              transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
              opacity: 0;
            }
            ~ .total {
              margin-top: 0 !important;
            }
          }
        }
        .total {
          border-top: 1px solid #bebebe;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;
          // margin-top: 15px;
          span {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      .produits-associes {
        padding-top: 30px;
        > p {
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          margin-left: -15px;
          margin-right: -15px;
          > div {
            padding: 15px;
            width: 50%;
            flex: 50%;
            max-width: 50%;
            @media (min-width: 1200px) {
              width: calc(100% / 3);
              flex: calc(100% / 3);
              max-width: calc(100% / 3);
            }

            .link {
              .content {
                padding: 10px 8px 0;
                .titre {
                  line-height: 15px;
                  letter-spacing: 0.24px;
                  font-size: 12px;
                  font-weight: 800;
                  padding-bottom: 15px;
                  padding-right: 20px;
                  margin-block-start: 0;
                  margin-block-end: 0;
                }
                .social-tags {
                  span {
                    color: $boutique_preview_produit_couleur_principale;
                  }
                }
                .tarif {
                  color: $boutique_preview_produit_couleur_principale;
                  font-size: 20px;
                  font-weight: bold;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  line-height: initial;
                  @media (min-width: 1200px) {
                    font-size: 26px;
                  }
                  span {
                    color: $boutique_preview_produit_couleur_principale;
                    font-size: 12px;
                    margin-left: 2px;
                    display: none;
                    @media (min-width: 1200px) {
                      display: inline-block;
                    }
                  }
                  &.raye {
                    font-size: 16px;
                    color: $boutique_preview_produit_couleur_prix_raye;
                    text-decoration: line-through;
                    span {
                      color: $boutique_preview_produit_couleur_prix_raye;
                    }
                    @media (min-width: 1200px) {
                      font-size: 18px;
                    }
                  }
                }
                .fake-link {
                  background: $boutique_preview_produit_couleur_secondaire;
                  height: 32px;
                }
                .fake-link-boutique {
                  background: $boutique_preview_produit_couleur_secondaire;
                  height: 32px;
                }
                .actions-post {
                  padding: 0;
                  justify-content: space-between;
                  height: auto;
                  align-items: flex-end;
                  .picto {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    min-height: 32px;
                    max-width: calc(100% - 45px);
                    .post-alert {
                      background: $boutique_preview_produit_couleur_alerte;
                      margin: 5px 0;
                      padding: 5px;

                      span {
                        color: #fff;
                        line-height: initial;
                        &:nth-child(1) {
                          font-size: 20px;
                          font-weight: bold;
                          padding-right: 5px;
                          @media (min-width: 1200px) {
                            font-size: 18px;
                          }
                        }
                        &:nth-child(2) {
                          font-size: 12px;
                        }
                      }
                      &.alert-promo,
                      &.alert-stock,
                      &.alert-stock-precommande {
                        display: flex;
                        flex-direction: column;
                      }
                      &.full {
                        background: $boutique_preview_produit_couleur_alerte_indisponible;
                        span {
                          font-size: 12px;
                          font-weight: normal;
                        }
                      }
                    }
                  }
                }
              }
              img {
                border-radius: 0;
              }
            }
          }
        }
      }
      .customize {
        cursor: pointer;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 10px;
        background: $boutique_produit_personnaliser_fond;
        border-top-left-radius: $input-radius * 4;
        border-top-right-radius: $input-radius * 4;
        box-shadow: 0 5px 38px rgba(0, 0, 0, 0.3), 0 0 12px rgba(0, 0, 0, 0.22);
        z-index: 10;
        // cursor: pointer;
        // * {
        //   pointer-events: none;
        // }

        > div {
          display: flex;
          align-items: center;
          span {
            margin: 0 6px;
            color: $boutique_produit_personnaliser_couleur;
            font-size: 16px;
            font-weight: bold;
            &:first-child {
              border-radius: 50%;
              height: 40px;
              width: 40px;
              background: $boutique_produit_personnaliser_couleur;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                fill: #fff;
                transform: rotate(45deg);
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
      .add-to-bag {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $boutique_produit_ajout_panier_fond;
        border-top-left-radius: $input-radius * 4;
        border-top-right-radius: $input-radius * 4;
        box-shadow: 0 5px 38px rgba(0, 0, 0, 0.3), 0 0 12px rgba(0, 0, 0, 0.22);
        z-index: 11;
        cursor: pointer;
        color: $boutique_produit_ajout_panier_couleur;
        * {
          color: $boutique_produit_ajout_panier_couleur;
        }
        &.disable {
          pointer-events: none;
          opacity: 0.3;
        }
        &.error {
          pointer-events: none;
          background: linear-gradient(to bottom, #df172f -25%, #56010b 125%);
          color: #fff;
          > div{
            span {
          svg {
            fill: white;
          }
        }
      }
          * {
            color: #fff;
            
          }
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            margin: 0 6px;
            color: $boutique_produit_ajout_panier_couleur;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-items: center;
            svg {
              fill: $boutique_produit_ajout_panier_couleur;
            
              width: 28px;
              height: 28px;
            }
          }
        }
      }
      .add-product {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0 5px 38px rgba(0, 0, 0, 0.3), 0 0 12px rgba(0, 0, 0, 0.22);
        z-index: 10;
        border-top-left-radius: $input-radius * 4;
        border-top-right-radius: $input-radius * 4;
        transform: translateY(100%);
        transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s;
        height: 400px;
        &.none {
          display: none !important;
        }
        .inner {
          background: #fff;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-top-left-radius: $input-radius * 4;
          border-top-right-radius: $input-radius * 4;
          position: relative;
          padding: 30px 20px 80px;
          > * {
            opacity: 0;
            transform: scale(0.8);
            transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            background: #fff;
          }
          .sizes {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            button {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              color: $boutique_produit_personnalisation_produit_couleur_principale;
              font-weight: 600;
              transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
              background: transparent;
              margin: 6px;
              &.active {
                color: #fff;
                background: $boutique_produit_personnalisation_produit_couleur_principale;
              }
            }
          }
          .quantity {
            padding: 20px 40px;
            width: 100%;
            z-index: 2;
            &.disable {
              pointer-events: none;
              opacity: 0.3;
            }
          }
          .informations {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            padding: 0 40px 20px;
            &.disable {
              pointer-events: none;
              opacity: 0.3;
            }
            button {
              // width: 38px;
              width: 100%;
              min-height: 38px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background: transparent;
              span {
                padding-right: 10px;
                color: $boutique_produit_personnalisation_produit_couleur_principale;
                font-size: 12px;
                font-weight: 500;
              }
              svg {
                width: 24px;
                height: 24px;
                fill: $boutique_produit_personnalisation_produit_couleur_principale;
              }
            }
          }
          .total {
            border-top: 1px solid $boutique_produit_color_total_line;
            padding: 10px 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            span {
              color: $boutique_produit_color_total;
              font-weight: bold;
              font-size: 16px;
              text-transform: uppercase;
            }
          }
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background: rgba($color: #333, $alpha: 0.5);
          opacity: 0;
        }
        &.active {
          transform: translateY(0);
          transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
          &::before {
            opacity: 1;
            transition: opacity 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.9s;
            height: 100vh;
          }

          .inner > * {
            opacity: 1;
            transform: scale(1);
            transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
          }
        }
      }
      .add-participant {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0 5px 38px rgba(0, 0, 0, 0.3), 0 0 12px rgba(0, 0, 0, 0.22);
        z-index: 10;
        border-top-left-radius: $input-radius * 4;
        border-top-right-radius: $input-radius * 4;
        transform: translateY(100%);
        @media (max-width: 1200px) {
          transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s;
        }
        > * {
          opacity: 0;
          transform: scale(0.8);
          @media (max-width: 1200px) {
            transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
          }
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background: rgba($color: #333, $alpha: 0.5);
          opacity: 0;
        }
        .header {
          padding: 10px 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #fff;
          border-top-left-radius: $input-radius * 4;
          border-top-right-radius: $input-radius * 4;

          .input-container {
            display: flex;
            align-items: center;
            justify-content: center;
            input {
              background: transparent;
              color: $boutique_produit_personnalisation_participants_couleur_principale;
              width: 125px;
              font-size: 18px;
              font-weight: bold;
              transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
              &::placeholder {
                font-size: 18px;
                color: $boutique_produit_personnalisation_participants_couleur_principale;
              }
              &:focus {
                width: calc(100% - 24px);
              }
            }
            .svg {
              display: flex;
              align-items: center;
              justify-content: center;
              // pointer-events: none;
              svg {
                fill: $boutique_produit_personnalisation_participants_couleur_principale;
                height: 24px;
                width: 24px;
              }
            }
          }
          // button * {
          //   pointer-events: none;
          // }
          > button {
            display: flex;
            align-items: center;
            background: none;

            .svg {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #000;
              width: 22px;
              height: 22px;
              margin-right: 10px;
              svg {
                fill: #000;
                transform: rotate(45deg);
                width: 10px;
                height: 10px;
              }
            }
          }
        }
        .list {
          height: 260px;
          overflow: auto;
          padding-bottom: 60px;
          background: #fff;
          button {
            padding: 10px 30px;
            display: flex;
            align-items: center;
            width: 100%;
            background: #fff;
            .visuel {
              min-height: auto;
              img,
              svg {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            .name {
              padding-left: 20px;
              font-weight: bold;
              text-align: left;
            }
            &.hide {
              display: none;
            }
          }
        }
        &.active {
          transform: translateY(0);
          @media (max-width: 1200px) {
            transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
          }
          &::before {
            opacity: 1;
            @media (max-width: 1200px) {
              transition: opacity 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.9s;
            }
            height: 100vh;
          }

          > * {
            opacity: 1;
            transform: scale(1);
            @media (max-width: 1200px) {
              transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
            }
          }
        }
      }
      .participant-form {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        border-top-left-radius: $input-radius * 4;
        border-top-right-radius: $input-radius * 4;
        box-shadow: 0 5px 38px rgba(0, 0, 0, 0.3), 0 0 12px rgba(0, 0, 0, 0.22);
        z-index: 10;
        transition: transform 0.01s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s opacity 0.01s cubic-bezier(0.165, 0.84, 0.44, 1)
          0.55s;

        opacity: 0;
        transform: translateY(100%);
        &:not(.active) {
          pointer-events: none;
        }
        > div {
          padding: 10px 30px;
          background: #fff;
          border-top-left-radius: $input-radius * 4;
          border-top-right-radius: $input-radius * 4;
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          > * {
            opacity: 0;
            transform: scale(0.8);
            transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
          }
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background: rgba($color: #333, $alpha: 0.5);
          opacity: 0;
        }
        &.active {
          transform: translateY(0);
          opacity: 1;
          > div > * {
            opacity: 1;
            transform: scale(1);
            transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s;
          }
          &::before {
            transition: 0.01s cubic-bezier(0.165, 0.84, 0.44, 1) 0.52s;
            height: 100vh;
            opacity: 1;
          }
        }
      }
      padding-bottom: 60px;
      &[data-step='participant_form'] {
        .add-participant {
          transform: translateY(100%);
          box-shadow: none;
          @media (max-width: 1200px) {
            transition: transform 0.01s cubic-bezier(0.165, 0.84, 0.44, 1) 0.52s;
          }
          &::before {
            @media (max-width: 1200px) {
              transition: height 0.01s cubic-bezier(0.165, 0.84, 0.44, 1) 0.52s;
            }
            height: 0;
          }
          > * {
            opacity: 0;
            transform: scale(0.8);
            @media (max-width: 1200px) {
              transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
          }
        }
      }
      &[data-step=''] {
        .participant-form {
          transform: translateY(100%);
          pointer-events: initial;
          @media (max-width: 1200px) {
            transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s;
          }
          > div > * {
            opacity: 0;
            transform: scale(0.8);
            @media (max-width: 1200px) {
              transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
            }
          }
        }
      }
    }
  }
  &.bag {
    .post {
      .container {
        padding-bottom: 120px;
        .customize {
          height: 120px;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    // .visuel .links {
    //   right: 20px;
    //   left: auto;
    //   span {
    //     position: relative;
    //   }
    // }
    .post {
      .container {
        // Personnalisation d'un produit (fixed en MB, en relative en DK)
        .tangible {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .customize {
            display: none !important;
          }
          .add-product {
            &.active {
              position: relative;
              order: 0;
              height: auto;
              box-shadow: none;
              background: none;
              z-index: 11;
              display: flex;
              justify-content: center;
              &::before {
                display: none;
              }
              .inner {
                padding: 30px 0;
                background: none;
                width: 350px;
                max-width: 100%;
                > * {
                  background: none;
                }
              }
            }
          }
          .add-to-bag {
            order: 1;
            position: relative;
            border-radius: $input-radius * 6;
            width: 350px;
            max-width: 100%;
            align-self: center;
          }
        }
        // FIN Personnalisation d'un produit (fixed en MB, en relative en DK)
        .participants {
          .list {
            margin-left: -10px;
            margin-right: -10px;
            display: flex;
            flex-wrap: wrap;
            z-index: 12;
            position: relative;
            .participant {
              margin: 10px;
            }
          }
          > .add-to-bag {
            display: flex;
            position: relative;
            border-radius: $input-radius * 6;
            width: 350px;
            max-width: 100%;
            margin: 20px auto;
          }
        }
        .intangible {
          padding: 10px 0;
          position: relative;
          margin: 0 10px;
          width: 260px;
          .customize {
            position: relative;
            height: auto;
            height: 100%;
            min-height: 70px;
            border-radius: $input-radius * 9;
            display: flex;
            align-items: center;
            padding-top: 0;
            width: 100%;
            cursor: pointer;
          }
          .add-participant {
            // &.active {
            position: absolute;
            top: 10px;
            border-radius: $input-radius * 9;
            &:not(.active) {
              z-index: -1;
              transform: translateY(0);
              pointer-events: none;
              visibility: hidden;
              height: 70px;
            }
            &.active {
              z-index: 13;
              height: 350px;
            }
            &::before {
              display: none;
            }
            .header {
              padding: 10px;
              border-top-left-radius: $input-radius * 9;
              border-top-right-radius: $input-radius * 9;
            }
            .list {
              margin: 0;
              border-bottom-left-radius: $input-radius * 9;
              border-bottom-right-radius: $input-radius * 9;
              display: block;
              height: 250px;
            }
            // }
          }
          .participant-form {
            position: absolute;
            top: 10px;
            // height: auto !important;
            border-radius: $input-radius * 9;
            // overflow: hidden;
            &.active {
              z-index: 14;
            }
            &::before {
              display: none;
            }
            > div {
              border-radius: $input-radius * 9;
              height: auto;
              padding: 30px 10px;
            }
          }
          .add-to-bag {
            display: none !important;
          }
        }
      }
    }
  }
}

@keyframes participantAppear {
  from {
    opacity: 0;
    transform: scale(0) translateY(15px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.decreasing-prices {
  padding: 0;
  .container {
    border-radius: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    > p {
      text-align: center;
      font-size: 18px;
      font-weight: 800;
      line-height: 1.28;
      text-transform: uppercase;
      padding-bottom: 10px;
      border-bottom: solid 1px #bebebe;
    }
    > span {
      margin: 5px 0;
      font-size: 12px;
    }
    .close {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $life_popup_fermer_fond;
      svg {
        fill: $life_popup_fermer_couleur;
        width: 12px;
      }
    }
    .customization {
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        width: calc(calc(100% / 3) - 3px);
        border-radius: $input-radius * 2;
      }
      .select {
        position: relative;
        display: flex;
        width: 100%;
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: calc(100% - 38px);
          background: #fff;
          height: 100%;
          border-top-left-radius: $input-radius * 2;
          border-bottom-left-radius: $input-radius * 2;
          font-weight: 600;
          font-size: 18px;
        }
        > button {
          background: $boutique_prix_deg_couleur_1;
          width: 38px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          border-top-right-radius: $input-radius * 2;
          border-bottom-right-radius: $input-radius * 2;
          svg {
            transform: rotate(90deg);
            fill: #fff;
            width: 16px;
            height: 16px;
            transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          }
        }
        .options {
          position: absolute;
          top: 0;
          background: #fff;
          display: none;
          z-index: 2;
          width: 100%;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          width: calc(100% - 38px);
          left: 0;
          border-top-right-radius: 0;
          border-top-left-radius: $input-radius * 2;
          border-bottom-right-radius: $input-radius * 2;
          border-bottom-left-radius: $input-radius * 2;
          overflow: scroll;
          max-height: 200px;
          height: auto;
          button {
            height: 40px;
            width: 100%;
            background: #fff;
            font-size: 18px;
            font-weight: 600;
            margin: 0;
            &.active {
              background: $boutique_prix_deg_fond_filtre;
            }
          }
        }
        &.active {
          > button {
            svg {
              transform: rotate(-90deg);
            }
          }
          .options {
            display: block;
          }
        }
      }

      > :nth-child(2) {
        background: $boutique_prix_deg_couleur_2;
        padding: 5px;
      }
      > :nth-child(3) {
        background: $boutique_prix_deg_couleur_3;
        padding: 5px;
      }
    }
    .array {
      margin-top: 10px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #fff;
          text-transform: uppercase;
          font-size: 12px;
          padding: 5px;
          width: calc(calc(100% / 3) - 3px);
        }
        :nth-child(3n+1) {
          background: $boutique_prix_deg_couleur_1;
          
        }
        :nth-child(3n+2) {
          background: $boutique_prix_deg_couleur_2;
        }
        :nth-child(3n+3) {
          background: $boutique_prix_deg_couleur_3;
          
        }
        :first-child{
          border-top-left-radius: $input-radius * 2; 
        }
        :last-child{
          border-top-right-radius: $input-radius * 2;
        }
      }
      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background: #fff;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: bold;
          padding: 5px;
          width: calc(calc(100% / 3) - 3px);
        }
        :nth-child(1) {
          color: $boutique_prix_deg_couleur_1;
        }
        :nth-child(2) {
          color: $boutique_prix_deg_couleur_2;
        }
        :nth-child(3) {
          color: $boutique_prix_deg_couleur_3;
        }
        &:last-child {
          :nth-child(1) {
            border-bottom-left-radius: $input-radius * 2;
          }
          :nth-child(3) {
            border-bottom-right-radius: $input-radius * 2;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    background: rgba(0, 0, 0, 0.7);
    .container {
      width: 450px;
      height: auto;
      max-height: calc(100% - 100px);
    }
  }
}
