#posts #loader-container {
  height: 100px;
}
#loader-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // background: rgba(#000, 0.2);
  z-index: 9999;
  max-width: 100%;
  flex: 100%;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    > div {
      position: absolute;
      border: 2px solid;
      border-color: transparent #fff;
      border-radius: 50%;
      animation: load 1s infinite ease-in-out;
    }
    .arc-1 {
      margin: 32% 0 0 32%;
      width: 36%;
      height: 36%;
    }
    .arc-2 {
      margin: 40% 0 0 40%;
      width: 20%;
      height: 20%;
      animation-direction: reverse;
      animation-delay: -0.5s;
    }
  }
  &.darken {
    .loader > div {
      border-color: transparent $loader_darken_color;
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
