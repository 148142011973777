form,
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .label-center {
    font-weight: bold;
    text-align: center;
    margin-bottom: -15px;
    padding-top: 20px;
  }
  .input-container {
    width: 100%;
    padding: 8px 0;
    position: relative;
    &.double {
      display: flex;
      justify-content: space-between;
      > div,
      > input {
        width: calc(50% - 10px);
      }
    }
    &.post-title {
      input {
        padding-right: 60px;
      }
      .title-limit {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 8px;
        font-size: 10px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .reset {
      position: absolute;
      right: 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      width: 25px;
      height: 25px;
      background: $administration_menu_annuler_fond;
      top: 50%;
      transform: translateY(-50%);
      svg {
        fill: $administration_menu_annuler_couleur;
        width: 12px;
        height: 12px;
      }
    }
    .more {
      button {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        width: 25px;
        height: 25px;
        background: $administration_menu_annuler_fond;
        svg {
          fill: $administration_menu_annuler_couleur;
          width: 12px;
          height: 12px;
          transform: rotate(45deg);
        }
      }
    }
    .input-password-visible {
      background: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      height: 35px;
      font-style: italic;
      font-size: 11px;
      font-weight: bold;
    }
    img {
      width: 100%;
    }
    .input-picture-container + .reset,
    img + .reset {
      top: 18px;
      transform: none;
    }
  }
  input,
  textarea {
    width: 100%;
    background: $input_fond;
    border-radius: $input_radius * 2;
    border: none;
    padding: 10px 12px;
    color: $input_couleur;
    font-size: 13px;
    outline: none;
    &::placeholder {
      font-size: 13px;
      color: $input_couleur;
    }
  }
}
