.quizbox-header {
    border-bottom: 1px solid #e2e2e2;
}

.quizbox-checkbox {
    box-shadow: #e2e2e2;
}

.quizbox-yesno {
    padding-left: 1rem;
}

.quizbox-yes {
    padding-right: 15px;

}

.quizbox-no {
    padding-left: 15px;
}