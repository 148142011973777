.template-new-password {
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
