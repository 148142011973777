.popup.picture {
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  > * {
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
  }
  cursor: url('../../img/zoom-out.png'), zoom-out;
  > div {
    cursor: initial;
  }
  .visuel {
    margin-right: -20px;
    margin-left: -20px;
    margin-top: -25px;
    position: relative;
    img {
      border-top-left-radius: $input_radius;
      border-top-right-radius: $input_radius;
      width: 100%;
      @media (min-width: 1200px) {
        max-height: calc(100vh - 250px);
        object-fit: cover;
      }
    }
    .links {
      position: absolute;
      right: 20px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      button,
      a {
        background: $article_sticky_likes_fond;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $article_sticky_likes_couleur;
        padding: 10px;
        border-top-right-radius: $input_radius * 3;
        border-bottom-left-radius: $input_radius * 3;
        transform: translateY(10px);
        font-size: 13px;
        height: 51px;
        margin: 0 10px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        svg {
          fill: $article_sticky_likes_couleur;
          stroke: $article_sticky_likes_couleur;
          transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .arrows {
    position: absolute;
    // top: 0;
    bottom: 0;
    // margin: auto;
    height: 40px;
    // display: none;
    align-items: center;
    justify-content: space-between;
    // NEW
    // margin: 0;
    display: flex;
    width: 100%;
    // top: auto;
    .arrow {
      span {
        color: #fff;
        padding: 0 5px;
      }
    }
    .arrow {
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: center;
      height: 40px;
      background: $article_sticky_likes_fond;
      margin: 0;
      padding: 5px;
      &.slide-prev {
        left: 0;
      }
      &.slide-next {
        right: 0;
        svg {
          transform: rotate(180deg);
        }
      }
      svg {
        transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        fill: #fff;
        will-change: auto;
      }
    }
    @media (min-width: 700px) {
      width: 600px;
    }
    @media (min-width: 1200px) {
      margin: auto;
      // width: 600p;
      top: 0;
      .arrow {
        width: 40px;
        background: none;
        padding: 0;
        span {
          display: none;
        }
        &.slide-prev {
          transform: translateX(-70px);
        }
        &.slide-next {
          transform: translateX(70px);
        }
        &:hover {
          &.slide-prev {
            svg {
              transform: scale(1.2);
            }
          }
          &.slide-next {
            svg {
              transform: rotate(180deg) scale(1.2);
            }
          }
        }
      }
    }
  }

  .close {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $life_popup_fermer_fond;
    svg {
      fill: $life_popup_fermer_couleur;
      width: 12px;
    }
  }
  .filters {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    padding-bottom: 5px;

    span {
      background: $life_popup_filtres_fond;
      border-radius: $input_radius;
      color: $life_popup_filtres_couleur;
      font-size: 11px;
      font-weight: 600;
      padding: 3px 5px;
      margin: 5px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 14px;
    position: relative;
    .admin-bar {
      right: -20px;
      top: -4px;
      > span {
        border-top-right-radius: 0;
      }
      a,
      button {
        margin: 0;
      }
    }
    .title {
      padding-bottom: 8px;
    }
  }
}
