.btn {
  border-radius: $bouton_contour;
  color: $bouton_couleur_texte !important;
  padding: 11px 22px;
  font-weight: 600;
  font-size: 14px;
  min-width: 100px;
  max-width: 100%;
  text-align: center;
}

.btn-primary {
  background: $bouton_principal_fond;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}



.btn-secondary-template-standard {
  color:var(--blank-color);
  background: var(--secondary-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.btn-secondary-template-monochrome{
  color:var(--primary-color) !important;
  background: var(--blank-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.btn-primary-ligther {
  background: $bouton_principal_lighter_fond;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.btn-second {
  color:$bouton_secondary_couleur_texte !important;
  background: $bouton_secondaire_fond;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.btn-third {
  margin: 15px 0;
  font-size: 13px;
  font-style: italic;
  font-weight: normal;
  padding: 0;
  text-align: center;
  color: $bouton_ternaire_couleur !important;
}

.btn-fourth {
  color: $bouton_couleur_texte;
}

.btn-validation {
  background: $boutique_bouton_principal_fond;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.link-back {
  position: absolute;
  top: 30px;
  left: 30px;

  svg {
    fill: $fleche_retour_couleur;
  }
}

.btn-rounded {
  padding: 6px 10px;

  &.rounded-horizontal {
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.btn-back {
  z-index: 9;
  width: 100%;
  position: relative;
  padding-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  overflow: visible;
  cursor: pointer;


  &.dark-bg {
    svg {
      fill: $bouton_couleur_texte;
    }
  }

  &.light-bg {
    svg {
      fill: $menu_burger_titre_couleur;
    }
  }

}

.back-arrow-container {
  cursor: pointer;
}