.template-profile {
  padding-top: 0;
  // margin-top: 0;
  position: relative;
  &::before {
    content: '';
    position: fixed;
    top: -50px;
    height: 350px;
    width: 150vw;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 60%;
    border-bottom-right-radius: 60%;
    background: $profil_cercle;
  }
  .loader-container {
    position: relative;
    height: 50vh;
    z-index: 1;
  }
  // TODO : voir si on fait comme ça partout
  #header {
    .navbar-top {
      @media (max-width: 1200px) {
        background: transparent;
      }
      .toggle span {
        background: $profil_burger_couleur;
      }
      .add-post {
        visibility: hidden;
      }
    }
  }
  #profile-picture {
    // margin: 0 -20px;
    margin-left: -10px;
    margin-right: -10px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    position: relative;
    overflow: hidden;

    .visuel {
      position: relative;
      #loader-container ~ img {
        opacity: 0;
      }
      input {
        display: none;
      }
      img,
      > svg {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        z-index: 1;
      }
      &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: auto;
        background: #fff;
        opacity: 0.15;
        height: 130px;
        width: 130px;
      }
      &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: auto;
        background: #fff;
        opacity: 0.1;
        height: 160px;
        width: 160px;
      }
      button {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -45px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $profil_edition_photo_fond;
        box-shadow: 0 0 20px 0 $profil_edition_photo_ombre;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        svg {
          fill: $profil_edition_photo_couleur;
          height: 15px;
          width: 15px;
        }
      }
    }
  }
  #profile-informations {
    transform: translateY(-120px);
    padding: 0 10px;
    .contact-informations {
      text-align: center;
      font-size: 16px;
      color: $annuaire_popup_nom_couleur;
      padding-top: 10px;
      .actions {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 50px 0 10px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background: $annuaire_popup_icone_secondaire_fond;
          padding: 10px;
          svg {
            fill: $annuaire_popup_icone_secondaire_couleur;
          }
          &:first-child {
            background: $annuaire_popup_icone_principale_fond;
            svg {
              fill: $annuaire_popup_icone_principale_couleur;
            }
          }
        }
      }
    }


    .club-privilege{
        background:var(--primary-color);
        color:#FFFFFF;
        padding:0.8rem 1rem;
        font-weight:600;
        border-radius:0.4rem;
        margin:1rem 0rem;
        width:50%;
        display:flex;
        justify-content:space-between;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        @media screen and (max-width:1200px) {
          width: 100%;
        }
}

    .container {
      margin: 20px 0;
      background: $profil_information_fond;
      border-radius: $input_radius * 2;
      padding-top: 10px;
      padding-bottom: 10px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      .bloc {
        padding: 10px 40px 10px 20px;
        font-size: 16px;
        color: $profil_information_couleur;
        display: flex;
        align-items: center;
        position: relative;
        > svg {
          margin-right: 10px;
          width: 14px;
          height: auto;
          fill: $profil_information_couleur;
        }
        label {
          font-size: 10px;
          position: absolute;
          top: -3px;
          padding: 0 40px 0 20px;
          left: 0;
          display: none;
        }
        input,
        p {
          font-size: 1rem;
          padding: 0;
          margin: 0;
          border: none;
          color: $profil_information_couleur;
          background: transparent;
          opacity: 1;
          -webkit-text-fill-color: $profil_information_couleur;
          width: 100%;
        }
        p {
          // width: calc(100% - 24px);
          overflow: auto;
        }
        .right {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 16px;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          .reset,
          .submit {
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            border-radius: 10px;
            svg {
              width: 10px;
              height: 10px;
            }
          }
          .reset {
            background: $profil_information_annuler;
            margin-right: 8px;
          }
          .submit {
            background: $profil_information_valider;
          }
          svg {
            fill: $profil_information_couleur;
            width: 15px;
            height: 15px;
          }
        }
        &.active,
        &.empty,
        &.disabled {
          label {
            display: block;
          }
        }
      }
    }
  }
  .true-false {
    background: $profil_information_fond;
    border-radius: $input_radius * 2;
    padding: 8px 20px;
    position: relative;
    z-index: 1;
    margin: 10px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    .label {
      color: $profil_information_couleur;
      font-size: 16px;
      font-weight: 800;
    }
    .choice {
      .inactive {
        background: $profil_offline_icone_fond;
        svg {
          fill: $profil_offline_icone_couleur;
        }
      }
      .active {
        background: $profil_online_icone_fond;
        svg {
          fill: $profil_online_icone_couleur;
        }
      }
    }
  }
  .first-connexion-submit-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    button {
      z-index: 10;
    }
  }
  @media (min-width: 1200px) {
    #header {
      background: #fff;
    }
    &::before {
      top: 115px;
      width: calc(100vw - 360px);
      height: calc(100vw - 360px);
      left: 0;
      transform: translateX(340px) translateY(-60%);
      border-radius: 50%;
    }
    #profile-picture {
      padding-top: 60px;
      height: auto;
      padding-bottom: 50px;
    }
    #profile-informations {
      display: flex;
      // justify-content: space-around;
      // flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      .container {
        width: 50%;
        margin: 10px 20px;
        position: relative;
        z-index: 1;
      }
      .legal-mentions,
      .take-break,
      .true-false {
        margin: 10px 20px;
        width: 50%;
      }
      .contact-informations {
        width: 100%;
      }
    }
    &.first-connexion-true {
      &::before {
        right: 0;
        margin: auto;
        transform: translateY(-60%);
      }
      .dk-grid {
        transform: none !important;
      }
    }
  }
}

.accordeon {
  z-index: 1;
  margin: 20px 0;
  .header {
    border-radius: $input_radius * 2;
    font-size: 16px;
    font-weight: 800;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    position: relative;
    width: calc(100% - 15px);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    .content {
      width: 100%;
    }
    .plus {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 40px;
      font-weight: 100;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      transform: translateX(50%);
      z-index: 2;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      svg {
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }
  }
  > .content {
    border-bottom-left-radius: $input_radius * 2;
    border-bottom-right-radius: $input_radius * 2;
    padding: 0 12px;
    width: calc(100% - 35px);
    margin-left: 10px;
    font-size: 0.8rem;
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
    max-height: 0;
    overflow: auto;
    * {
      opacity: 0;
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
  &.active {
    .header .plus svg {
      transform: rotate(0);
    }
    > .content {
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      padding: 8px 12px;
      max-height: initial;
      * {
        transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
        opacity: 1;
      }
    }
  }
  &.legal-mentions {
    .header {
      background: $profil_information_fond;
      .content {
        color: $profil_information_couleur;
      }
      .plus {
        background: $profil_cgu_icone_fond;
        svg {
          fill: $profil_cgu_icone_couleur;
        }
      }
    }
    > .content {
      background: $profil_cgu_fond;
      color: $profil_cgu_couleur;
      font-weight: 500;
      line-height: 1.3;
      h2 {
        padding: 30px 0 10px;
      }
    }
    &.active {
      > .content {
        max-height: 400px;
      }
    }
  }
  &.take-break {
    .header {
      background: $profil_snooze_header_fond;
      .content {
        color: $profil_snooze_header_couleur;
      }
      .plus {
        background: $profil_sooze_icone_inactive_fond;
        svg {
          fill: $profil_sooze_icone_inactive_couleur;
        }
      }
    }
    > .content {
      background: $profil_snooze_fond;
      color: $profil_snooze_couleur;
      form {
        padding: 20px 0;
        .date {
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          input {
            margin: 0 10px;
          }
        }
        button {
          margin: 10px;
        }
      }
    }
    &.active {
      .header {
        .plus {
          background: $profil_sooze_icone_active_fond;
          svg {
            fill: $profil_sooze_icone_active_couleur;
          }
        }
      }
      > .content {
        // padding-top: 15px;
        // padding-bottom: 30px;
        max-height: initial;
      }
    }
  }
}
