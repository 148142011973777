.slider-container {
  position: relative;
  width: 100%;
}
.slider {
  position: relative;
  .slick-dots {
    bottom: 0;
    li {
      margin: 0;
      button:before {
        font-size: 12px;
        color: $article_galerie_fleches_fond;
        opacity: 0.5;
      }
      &.slick-active button::before {
        opacity: 1;
      }
    }
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  &--content {
    outline: none !important;
  }
  &--btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto !important;
    border-radius: 50%;
    background: $article_galerie_fleches_fond;
    z-index: 2;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    svg {
      fill: $article_galerie_fleches_color;
      width: 25px;
      height: 25px;
    }
  }
  &--prev {
    left: 20px;
  }
  &--next {
    right: 20px;
    svg {
      transform: rotate(180deg);
    }
  }
  img {
    object-fit: cover;
  }
  &.unique {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      cursor: auto;
    }
  }
}

.gallery-slider {
  padding: 30px 0;
  .slick-list {
    overflow: initial;
  }
  .slider--content {
    padding: 0 15px;
  }
  .slider--prev {
    @media (min-width: 1200px) {
      left: -60px;
    }
  }
  .slider--next {
    @media (min-width: 1200px) {
      right: -60px;
    }
  }
  img {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-radius: $input_radius;
    cursor: url('../../img/zoom-in.png'), zoom-in;
    width: 100%;
    max-height: 350px;
    @media (min-width: 1200px) {
     width: auto;
    }
  }
}
