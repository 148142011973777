.template-cart {
  padding-left: 20px;
  padding-right: 20px;
  background: #f9f9f9;
  > div {
    background: #f9f9f9;
  }
  @media (min-width: 1200px) {
    #header .navbar-top .tab {
      left: 0;
      width: 100%;
    }
    .dk-grid {
      transform: none !important;
    }
  }
  #header .navbar-top {
    background: #f9f9f9;
  }
  .wait {
    height: calc(100vh - 75px);
    position: relative;
  }
  #content {
    @media (min-width: 1200px) {
      width: 620px;
      margin: auto;
    }
    > .error {
      width: 100%;
      max-width: 100%;
      flex: 1;
      text-align: center;
      padding: 20px;
      font-size: 14px;
      font-weight: 500;
      // color: #e20714;
    }
    h1 {
      color: $boutique_panier_titre_couleur;
      font-size: 18px;
      font-weight: 800;
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #bebebe;
    }
    #code-promo {
      background: #e9e9e9;
      border-radius: $input-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 5px;
      margin: 10px 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      @media (min-width: 1200px) {
        width: 350px;
        margin: 10px auto;
      }
      > * {
        margin: 5px;
      }
      p {
        color: #000;
        font-size: 12px;
        font-weight: bold;
      }
      form {
        width: 160px;
        max-width: 100%;
        flex-direction: row;
        input {
          width: calc(100% - 35px);
        }
        button {
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
          transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: $input-radius * 2;
          background: $boutique_panier_promo_valider_fond;
          color: $boutique_panier_promo_valider_couleur;
          height: 35px;
          width: 35px;
          font-weight: bold;
          &:hover {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
          }
        }
      }
      input {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        background: #fff;
      }
    }
    #code-promo-result {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: $boutique_panier_promo_message_couleur;
    }
    .products {
      .item {
        margin: 20px 0;
        .inner {
          background: #fff;
          border-radius: $input-radius * 2;
          position: relative;
          padding: 15px 10px 25px 10px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          > button {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            background: $boutique_panier_produit_suppression_fond;
            border-radius: 50%;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            &:hover {
              transform: translate(50%, -50%) scale(1.3);
            }
            svg {
              fill: $boutique_panier_produit_suppression_couleur;
              height: 10px;
              width: 10px;
            }
          }
          .header {
            display: flex;
            a {
              color: #000;
            }
            .titre {
              width: 50%;
            }
            > div {
              display: flex;
              h2,
              p {
                font-size: 16px;
                font-weight: bold !important;
              }
              span {
                font-size: 11px;
              }
              &:nth-child(1) {
                width: 50%;
                padding-right: 5px;
                justify-content: flex-start;
                flex-direction: column;
              }
              &:nth-child(2) {
                width: 40px;
                padding: 0 5px;
                justify-content: center;
                text-align: center;
              }
              &:nth-child(3) {
                width: calc(50% - 40px);
                justify-content: flex-end;
                text-align: right;
                padding-left: 5px;
              }
            }
          }
          .list {
            height: 210px;
            overflow: auto;
            background: #fff;
            button {
              padding: 10px 30px;
              display: flex;
              align-items: center;
              width: 100%;
              background: #fff;
              .visuel {
                min-height: auto;
                img,
                svg {
                  height: 50px;
                  width: 50px;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
              .name {
                padding-left: 20px;
                font-weight: bold;
                text-align: left;
              }
              &.hide {
                display: none;
              }
            }
          }
          .stock-full {
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            color: #df172f;
            padding-top: 10px;
          }
        }
        .promo {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 30px;
          span {
            color: #9b9b9b;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      .service {
        .inner {
          padding-bottom: 10px;
        }
        .participants {
          padding-top: 10px;
          .participant {
            display: flex;
            margin: 8px 0;
            align-items: center;
            * {
              font-size: 14px;
              font-weight: 500;
            }
            button {
              width: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: none;
              height: 22px;

              svg {
                transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                width: 17px;
                height: 17px;
                fill: $boutique_panier_service_suppression_couleur;
              }
              &:hover svg {
                width: 22px;
                height: 22px;
              }
            }
            .name {
              width: calc(50% - 25px);
              padding: 0 5px;
            }
            .quantity {
              width: 40px;
              display: flex;
              justify-content: center;
              text-align: center;
              padding: 0 5px;
            }
            .price {
              padding-left: 5px;
              width: calc(50% - 40px);
              display: flex;
              justify-content: flex-end;
              text-align: right;
            }
          }
        }
        .customize {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .add {
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            font-size: 14px;
            font-weight: bold;
            color: $boutique_produit_personnaliser_couleur;
            padding: 10px;
            transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

            span {
              background: $boutique_produit_personnaliser_couleur;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 6px;
              transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
              svg {
                transform: rotate(45deg);
                fill: #fff;
                width: 15px;
                height: 15px;
              }
            }
            &:hover {
              transform: scale(1.1);
              span {
                box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
              }
            }
          }
          .add-participant {
            display: none;
            .header {
              // padding: 10px 30px;
              display: flex;
              flex-direction: column;
              align-items: center;
              background: #fff;
              border-top-left-radius: $input-radius * 4;
              border-top-right-radius: $input-radius * 4;
              padding-bottom: 10px;
              .input-container {
                display: flex;
                align-items: center;
                justify-content: center;
                input {
                  background: transparent;
                  color: $boutique_produit_personnalisation_participants_couleur_principale;
                  width: 125px;
                  font-size: 18px;
                  font-weight: bold;
                  transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
                  &::placeholder {
                    font-size: 18px;
                    color: $boutique_produit_personnalisation_participants_couleur_principale;
                  }
                  &:focus {
                    width: calc(100% - 24px);
                  }
                }
                .svg {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  // pointer-events: none;
                  svg {
                    fill: $boutique_produit_personnalisation_participants_couleur_principale;
                    height: 24px;
                    width: 24px;
                  }
                }
              }
              // button * {
              //   pointer-events: none;
              // }
              > button {
                display: flex;
                align-items: center;
                background: none;

                .svg {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid #000;
                  width: 22px;
                  height: 22px;
                  margin-right: 10px;
                  svg {
                    fill: #000;
                    transform: rotate(45deg);
                    width: 10px;
                    height: 10px;
                  }
                }
              }
            }
          }
          .participant-form {
            display: none;
          }
        }
        &.step1 {
          .customize {
            .add {
              display: none;
            }
            .add-participant {
              display: block;
            }
          }
        }
        &.step2 {
          .customize {
            .add {
              display: none;
            }
            .add-participant {
              display: none;
            }
            .participant-form {
              display: block;
            }
          }
        }
      }
      .product {
        .content {
          display: flex;
          align-items: center;
          padding-top: 10px;
          flex-wrap: wrap;
          .quantity-selector {
            width: 100%;
          }
          .informations {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            button {
              // width: 38px;
              width: 100%;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background: transparent;
              padding-right: 7px;
              span {
                padding-right: 10px;
                color: $boutique_produit_personnalisation_produit_couleur_principale;
                font-size: 12px;
                font-weight: 500;
              }
              svg {
                width: 24px;
                height: 24px;
                fill: $boutique_produit_personnalisation_produit_couleur_principale;
              }
            }
          }
          @media (min-width: 700px) {
            .quantity-selector,
            .informations {
              width: 50%;
            }
            .informations button {
              padding-right: 0;
            }
          }
        }
      }
    }
    .total {
      border-top: 1px solid #bebebe;
      display: flex;
      justify-content: space-between;
      padding: 10px 10px 0;
      p {
        font-size: 14px;
        font-weight: bold;
      }
      &.full {
        p {
          font-weight: 500;
        }
      }
      + .total {
        border: none;
      }
    }
    .promo {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      p {
        font-size: 14px;
        font-weight: 600;
        color: #9b9b9b;
      }
      + .total {
        border: none;
      }
    }
    .address {
      border-radius: $input-radius * 2;
      background: #e9e9e9;
      padding: 15px 35px;
      margin: 30px 0 10px;
      div {
        position: relative;
        input {
          color: #4a4a4a;
          font-size: 16px;
          display: block;
          width: 100%;
          border: none;
          background: none;
          margin: 5px 0;
          &::placeholder {
            color: #4a4a4a;
          }
        }
        button {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          height: 15px;
          width: 15px;
          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
    .address-choice {
      margin: 10px 0;
      .option {
        background: #fff;
        border-radius: $input_radius * 2;
        padding: 8px 20px;
        position: relative;
        z-index: 1;
        .label {
          // color: $profil_information_couleur;
          // font-size: 16px;
          // font-weight: 800;
        }
        .choice {
          .inactive {
            background: $profil_offline_icone_fond;
            svg {
              fill: $profil_offline_icone_couleur;
            }
          }
          .active {
            background: $profil_online_icone_fond;
            svg {
              fill: $profil_online_icone_couleur;
            }
          }
        }
      }
    }
    .address-customized {
      background: #fff;
      border-radius: $input-radius * 2;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      padding: 10px 15px 20px 15px;
      &.hide {
        display: none;
      }
      + p {
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: #df172f;
        padding-top: 10px;
      }
    }
    .thanks {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 30px 0 20px;
      span {
        border-radius: 50%;
        background: $boutique_panier_validation_fond;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin-bottom: 15px;
        svg {
          fill: $boutique_panier_validation_couleur;
          width: 25px;
          height: 25px;
        }
      }
    }

    .steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      button {
        margin: 5px;
      }
      @media (min-width: 700px) {
        flex-direction: row-reverse;
        button {
          margin: 5px 30px;
        }
      }
    }
  }
  #signature {
    margin-top: 30px;
    > div {
      background: #fff;
      position: relative;
      border-radius: $input-radius * 2;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      height: 300px;
      max-height: 50vh;
      margin-top: 20px;
    }

    #signature-pad {
      height: 100%;
      .m-signature-pad--body {
        height: 100%;
      }
      canvas {
        width: 100%;
        height: 100%;
      }
    }
    button {
      background: #000;
      min-width: auto;
      padding: 5px 10px;
      border-radius: $input-radius * 2;
      font-size: 12px;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  .signature-msg {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #e20714;
    padding-top: 10px;
  }
  .step3 {
    .item {
      .inner {
        padding-bottom: 15px !important;

        > button {
          display: none !important;
        }
      }
      &.product {
        .content {
          .quantity-selector {
            filter: grayscale(1);
            pointer-events: none;
          }
          .informations {
            display: none !important;
          }
        }
      }
      &.service {
        .participant > button {
          visibility: hidden !important;
          pointer-events: none !important;
        }
        .customize {
          display: none !important;
        }
      }
    }
  }
  .step4 {
    .steps {
      flex-direction: column !important;
      .btn {
        margin: 15px;
      }
    }
  }
}
