@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Bold'), url(../assets/fonts/Gilroy/Gilroy-Bold.ttf) format('truetype');
  font-weight: bold;

}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Regular'), url(../assets/fonts/Gilroy/Gilroy-Regular.ttf) format('truetype');

}


@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy-Light'), url(../assets/fonts/Gilroy/Gilroy-Light.ttf) format('truetype');
  font-weight: 300;

}



* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:after,
*:before {
  text-rendering: optimizeLegibility;
}

html {
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  // width: 100vw;
  // overflow-x: hidden;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  hyphens: none;
  position: relative;
  overflow-x: hidden;
  background: white;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: $lien_couleur_defaut;

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    text-decoration: none;
  }
}

sup {
  line-height: 0;
}

p {
  margin: 0;
  padding: 0;
}

/*------------------------------------*\
* RESPONSIVE
\*------------------------------------*/
@-ms-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

input,
select,
textarea {
  margin: 0;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  appearance: none;
  cursor: pointer;
  border: none;
  padding: 0;
}

button:focus,
input[type='button']:focus,
input[type='reset']:focus,
input[type='submit']:focus {
  outline: none;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='search'] {
  appearance: textfield;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  appearance: none;
}

textarea {
  overflow: auto;
}

textarea.vertical-only {
  width: 100%;
  resize: vertical;
  min-height: 2rem;
  white-space: pre-line;
  word-wrap: break-word;
}


input[type='text']:focus,
input[type='email']:focus,
input[type='tel']:focus,
input[type='date']:focus,
textarea:focus,
select:focus {
  outline: none;
}

.long-value-input {
  // padding-right: 2.5rem !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.switch-wrapper {
  position: relative;

  >div {
    position: absolute;
    width: 100vw;
  }
}

.hidden {
  display: none !important;
}


