.common-administration {
  background: $administration_fond_page;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  #header {
    .navbar-top {
      
      .tab {
        .title {
          background: $administration_menu_titre_fond;
          h1 {
            color: $administration_menu_titre_couleur;
          }
        }
      }
      .toggle {
        span {
          background: $administration_menu_burger_couleur;
        }
      }
      .close {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $administration_menu_annuler_fond;
        position: absolute;
        bottom: 8px;
        right: 18px;
        svg {
          fill: $administration_menu_annuler_couleur;
          width: 12px;
        }
      }
    }
  }
}

.template-new-post {
  .popup.picture {
    .container {
      box-shadow: none;
      padding: 0;
      background: transparent;
      width: auto;
      @media (min-width: 1200px) {
        max-width: 600px;
      }
    }
    .visuel {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      img {
        width: auto;
        max-width: 100%;
        max-height: 80vh;
      }
    }
    .close {
      right: 0;
      top: 0;
      display: none;
    }
  }
  #content {
    border-radius: $input_radius;
    background: $administration_bloc_fond;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 1;
    .prev,
    .next {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 2;
      border-radius: 50%;
      margin: auto;
      width: 30px;
      height: 30px;
      background: $administration_etapes_icone_fond;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 20px;
        width: 20px;
        fill: $administration_etapes_icone_couleur;
      }
    }
    .prev {
      left: 0;
      transform: translateX(-50%);
      svg {
        transform: rotate(180deg);
      }
    }
    .next {
      right: 0;
      transform: translateX(50%);
    }

    > .header {
      height: 46px;
      display: flex;
      align-items: center;
      border-top-left-radius: $input_radius;
      border-top-right-radius: $input_radius;
      overflow: hidden;
      span {
        width: 50%;
        display: flex;
        text-align: center;
        justify-content: center;
        font-size: 0.9rem;
        font-weight: 800;
        letter-spacing: 1.2px;
        color: $administration_etapes_header_item_inactif_couleur;
        height: 100%;
        align-items: center;
        text-transform: uppercase;
        cursor: pointer;
        &.active {
          background: $administration_etapes_header_item_actif_fond;
          color: $administration_etapes_header_item_actif_couleur;
        }
        &:nth-child(1) {
          border-bottom-right-radius: $input_radius * 5;
        }
        &:nth-child(2) {
          border-bottom-left-radius: $input_radius * 5;
        }
      }
    }
    .inner {
      padding: 40px 20px;
      position: relative;
      .category {
        padding: 7px 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .input {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background: $administration_etapes_categorie_icone_fond_inactif;
          margin-right: 17px;
          svg {
            width: 8px;
            height: 8px;
            fill: $administration_etapes_categorie_icone_couleur;
          }
        }
        .label {
          font-weight: 800;
          color: $administration_label_couleur;
          font-size: 1rem;
        }
        &[data-active='true'] {
          .input {
            background: $administration_etapes_categorie_icone_fond_actif;
          }
        }
      }
    }
    .indicators {
      position: relative;
      height: 35px;
      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 5px;
        border-radius: 6px;
        background: $administration_etapes_indicateur_fond_inactif;
        width: 36px;
        transform: scaleX(0.5);
      }
      &::before {
        right: calc(50% + 3px);
        transform-origin: right;
      }
      &::after {
        transform-origin: left;
        left: calc(50% + 3px);
      }
    }
    &[data-active='1'] {
      .indicators::before {
        background: $administration_etapes_indicateur_fond_actif;
        transform: scaleX(1);
      }
    }
    &[data-active='2'] {
      .indicators::after {
        background: $administration_etapes_indicateur_fond_actif;
        transform: scaleX(1);
      }
    }
    .form {
      padding: 0 5px;
      input,
      textarea {
        background: $administration_input_fond;
      }
      input[type='file'] {
        display: none;
      }
      img {
        border-radius: $input_radius;
      }
      #calendar {
        margin-right: -25px;
        margin-left: -25px;
        width: calc(100% + 50px);
        .dates .empty .date {
          width: calc(calc(100vw - 100px) / 7);
        }
        @media (min-width: 1200px) {
          width: 500px;
          .dates .empty .date {
            width: calc(calc(500px - 60px) / 7);
          }
        }
      }
      .time {
        &.active input {
          background: $administration_input_datepicker_fond_actif;
          color: $administration_input_datepicker_couleur_actif;
          &::placeholder {
            color: $administration_input_datepicker_couleur_actif;
          }
        }
      }
      .clock {
        display: none;
        &.active {
          display: flex;
          align-items: center;
          span {
            margin: 0 10px;
          }
        }
      }
      .input-access-finder {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          border-style: solid;
          border-width: 1px;
          background: $administration_input_import_fichier_fond;
          border-radius: 23.5px;
          padding: 11px;
          font-weight: 600;
          display: flex;
          align-items: center;
          font-size: 0.9rem;
          color: $administration_input_import_fichier_couleur;
          position: relative;
          svg {
            fill: $administration_input_import_fichier_couleur;
            margin-right: 6px;
          }
          &::after {
            border-radius: 23.5px;
            position: absolute;
            top: -1px;
            left: -1px;
            content: '';
            background: $administration_input_import_fichier_bordure;
          }
        }
      }
      .social-network {
        width: auto;
        max-width: 100%;
        > div {
          border-radius: $input_radius * 2;
          // overflow: hidden;
          display: flex;
          button {
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            position: relative;
            z-index: 1;
            overflow: hidden;
            transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            &::before {
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: $administration_reseaux_sociaux_actif_fond;
              content: '';
              transform: scaleX(0);
              transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            }
            &.active {
              color: $administration_reseaux_sociaux_actif_couleur;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
                0 1px 2px rgba(0, 0, 0, 0.24);
              &::before {
                transform: scaleX(1);
              }
            }

            &:nth-child(1) {
              border-top-left-radius: $input_radius * 2;
              border-bottom-left-radius: $input_radius * 2;
              &::before {
                transform-origin: right;
              }
            }
            &:nth-child(2) {
              border-top-right-radius: $input_radius * 2;
              border-bottom-right-radius: $input_radius * 2;
              &::before {
                transform-origin: left;
              }
            }
            &:hover {
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
                0 1px 2px rgba(0, 0, 0, 0.24);
            }
          }
        }
      }
      .suggestion-container {
        .suggestion-list {
          position: absolute;
          width: 100%;
          margin-top: 5px;
          background: $administration_suggestion_fond;
          border-radius: 10px;
          display: none;
          flex-direction: column;
          overflow: hidden;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
          padding-top: 5px;
          padding-bottom: 5px;
          z-index: 3;
          button {
            text-align: left;
            font-size: 13px;
            padding: 5px 12px;
            color: $administration_suggestion_couleur;
            background: transparent;
          }
        }
        input:focus ~ .suggestion-list,
        input:visited ~ .suggestion-list,
        &:hover .suggestion-list {
          display: flex;
        }
      }
      .hashtags {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        @media (min-width: 1200px) {
          width: calc(100% + 20px);
          margin-left: -10px;
          margin-right: -10px;
        }
        .add {
          width: 35px;
          height: 35px;
          border-radius: $input_radius * 2;
          margin-top: 8px;
          svg {
            width: 12px;
            height: 12px;
            transform: rotate(45deg);
            fill: $administration_hashtag_supprimer_couleur;
          }
          @media (min-width: 1200px) {
            margin-left: 10px;
          }
        }
        .input-container {
          width: 100%;
          @media (min-width: 1200px) {
            width: 260px;
            max-width: 100%;
            margin: 0 10px;
          }
          span {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 30px;
            background: $administration_hashtag_fond;
            color: $administration_hashtag_couleur;
            margin: auto;
            border-top-left-radius: $input_radius * 2;
            border-bottom-left-radius: $input_radius * 2;
          }
          input {
            padding-left: 40px;
            padding-right: 35px;
          }
          .reset {
            background: transparent;
            right: 5px;
            svg {
              fill: $administration_hashtag_supprimer_couleur;
            }
          }
          .suggestion-list {
            button {
              padding: 5px 35px 5px 40px;
            }
          }
        }
      }
      .gallery {
        align-items: center;
        justify-content: flex-start;
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        display: flex;
        flex-wrap: wrap;
        padding-top: 6px;
        @media (min-width: 1200px) {
          padding-top: 0;
        }
        .item {
          width: 50%;
          max-width: 50%;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          max-height: 200px;
          height: 150px;
          @media (min-width: 1200px) {
            height: 200px;
            width: 25%;
            max-width: 25%;
          }
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
            cursor: url('../../img/zoom-in.png'), zoom-in;
          }
          button:not(.reset) {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: $input_radius * 2;
          }
          .reset {
            position: absolute;
            right: 15px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            width: 25px;
            height: 25px;
            background: $administration_menu_annuler_fond;
            top: 15px;
            svg {
              fill: $administration_menu_annuler_couleur;
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
  #email {
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: $administration_bloc_fond;
    padding: 11px;
    border-radius: $input_radius;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    .input {
      width: 16px;
      height: 16px;
      border: solid 2px $administration_input_email_contour;
      border-radius: 50%;
      transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    .label {
      font-weight: 800;
      color: $administration_label_couleur;
      font-size: 1rem;
      padding: 0 10px;
      .second {
        font-size: 0.8rem;
      }
    }
    &.active {
      .input {
        background: $administration_input_email_actif;
      }
    }
  }
  #recipients {
    > p {
      text-align: center;
      color: $administration_destinataires_label_couleur;
      padding: 6px 0;
      &.title {
        font-size: 1rem;
        letter-spacing: 1.07px;
      }
    }
    .accordeon {
      margin-top: 10px;
      .header {
        border-radius: $input_radius;
        background: $administration_bloc_fond;
      }
      &.active .header + .content {
        min-height: 50px;
        background: $administration_destinataires_accordeon_fond;
        max-height: 300px;
      }
      .content {
        position: relative;
        .group {
          padding: 7px 10px;
          display: flex;
          align-items: center;
          .input {
            width: 16px;
            height: 16px;
            border: solid 2px $administration_destinataires_checkbox_contour;
            border-radius: 50%;
            transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          }
          .label {
            padding: 0 10px;
            font-size: 1rem;
            color: $administration_destinataires_accordeon_couleur;
          }
          &[data-active='true'] {
            .input {
              background: $administration_destinataires_checkbox_active;
            }
          }
        }
      }
    }
  }

  #actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    button {
      margin: 10px 0;
    }
  }

  .popup.creation {
    #loader-container {
      .loader > div {
        border-color: transparent $loader_darken_color;
      }
    }
  }
}

.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
}
