.common-connexion {
  background: $connexion_fond_page;
  min-height: 100vh;
  h1 {
    font-size: 13px;
    color: $connexion_couleur_titre;
    font-weight: normal;
    margin: 0;
    text-align: center;
  }
  .logo {
    height: $connexion_desktop_logo_height;
    position: relative;
    z-index: 1;
  }
  .btn-primary {
    margin-top: 20px;
   
  }
  .btn {
    position: relative;
  }
  .innerWidth {
    padding: 0 10px;
  }
  form {
    @media (min-width: 700px) and (max-width: 1200px) {
      max-width: 600px;
      margin: auto;
    }
    @media (min-width: 1200px) {
      width: 400px;
    }
  }
}
