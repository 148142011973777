.rdw-editor-wrapper {
  border-radius: $input_radius;
  background: #fff;
  // overflow: hidden;
  .rdw-editor-toolbar {
    border-radius: 0;
    border-top-left-radius: $input_radius;
    border-top-right-radius: $input_radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    border-bottom: 1px solid $administration_bloc_fond;
  }

  div[title='Strikethrough'],
  div[title='Barrer'],
  div[title='Monospace'],
  div[title='Exposant'],
  div[title='Indice'],
  div[title='Souligner'],
  div.rdw-fontsize-wrapper,
  .rdw-fontfamily-wrapper,
  .rdw-option-disabled,
  .rdw-colorpicker-wrapper,
  .rdw-embedded-wrapper,
  .rdw-image-wrapper,
  .rdw-remove-wrapper {
    display: none !important;
  }
  .rdw-justify-aligned-block * {
    text-align: justify;
  }
  .rdw-center-aligned-block * {
    text-align: center;
  }
  #openLinkInNewWindow {
    width: auto;
  }
  .public-DraftEditorPlaceholder-root {
    color: #000;
  }
  .rdw-link-modal-input {
    border-radius: $input_radius;
    border: 1px solid #bebebe;
  }
  img {
    object-fit: contain;
    width: auto !important;
    border-radius: 0 !important;
  }
  .rdw-dropdown-selectedtext {
    color: #000;
  }
  .rdw-option-wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdw-editor-main {
    border: none;
    padding: 10px 12px;
    color: #000000;
    font-size: 13px;
    outline: none;
    a {
      color: #000;
      text-decoration: underline;
    }
  }
  .public-DraftStyleDefault-block {
    margin: 0;
  }
}
