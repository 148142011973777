.template-first-connexion {
  padding: 80px 0;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    form {
      padding: 50px 0;
    }
  }
}
