#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  * {
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .navbar-top {
    width: 100vw;
    height: 50px;
    display: flex;
    justify-content: space-between;
    background: transparent;
    align-items: center;
    background: $menu_mobile_fond;

    @media (min-width: 700px) and (max-width: 1200px) {
      background: transparent;
    }

    .dk-logo {
      display: none;
    }

    .nav-background {
      display: none;
      background: $menu_mobile_titre_fond !important;
    }

    .home-button {
      height: 25px;
      width: 25px;
      border: 2px solid $menu_nouveau_post_contour;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 40px;
      font-weight: 100;
      position: absolute;
      z-index: 2;
      right: 20px;
      bottom: 9px;
      transition: none;

      svg {
        fill: white;
      }

      @media (min-width: 1200px) {
        // border-color: $menu_desktop_nouveau_post_contour;
        border: none;
        height: auto;
        width: auto;
        // color: $menu_desktop_nouveau_post_couleur;
        color: white;
        padding: 11px 22px;
        font-weight: 600;
        font-size: 14px;
        bottom: auto;
        top: calc(115px / 2);
        transform: translateY(-50%);
        min-height: 47px;
        right: 100px;
      }

      @media (min-width: 1400px) {
        right: calc(50vw - 520px);
      }
    }

    .tab {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 100%;
      pointer-events: none;

      .svg-right-lead,
      .svg-right {
        transform: rotateY(180deg);
      }

      .title-leads {
        width: calc(100% - 160px);
        text-align: center;
        background: #002e5a;
        padding: 10px, 0px, 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
          position: relative;
          z-index: 2;
          font-size: 13px;
          letter-spacing: 1.4px;
          text-transform: uppercase;
          font-weight: normal;
          color: $menu_mobile_titre_couleur;
        }

        .version {
          align-self: center;
          padding-top: 10px;
          font-size: 8px;
          color: $menu_mobile_titre_couleur;
        }
      }

      .title {
        width: calc(100% - 160px);
        text-align: center;
        background: $menu_mobile_titre_fond;
        padding: 10px, 0px, 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
          position: relative;
          z-index: 2;
          font-size: 13px;
          letter-spacing: 1.4px;
          text-transform: uppercase;
          font-weight: normal;
          color: $menu_mobile_titre_couleur;
        }

        .version {
          align-self: center;
          padding-top: 10px;
          font-size: 8px;
          color: $menu_mobile_titre_couleur;
        }
      }
    }

    .return {
      position: absolute;
      z-index: 2;
      bottom: 12px;
      left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        fill:#FFFF;
        transform: rotate(180deg);
      }
      
      @media (min-width: 1200px) {
        display: none;
      }
    }

    .toggle {
      position: absolute;
      z-index: 2;
      background: none;
      height: 15px;
      width: 23px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0;
      left: 20px;
      bottom: 12px;

      span {
        width: 23px;
        height: 2px;
        background: $menu_burger_couleur;

        &:last-child {
          width: 11px;
        }
      }

      @media (min-width: 1200px) {
        display: none;
      }
    }

    .add-post {
      height: 25px;
      width: 25px;
      border: 2px solid $menu_nouveau_post_contour;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 25px;
      font-weight: 300;
      position: absolute;
      z-index: 2;
      right: 20px;
      bottom: 9px;
      color: $menu_nouveau_post_couleur;
      transition: none;
      &:hover{
        background:$menu_nouveau_post_contour;
        color:#FFFFFF;
      }
      .mb{
        margin-top: -2px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .dk {
        display: none;
      }

      @media (min-width: 1200px) {
        // border-color: $menu_desktop_nouveau_post_contour;
        border: none;
        height: auto;
        width: auto;
        // color: $menu_desktop_nouveau_post_couleur;
        background: var(--ui-primary-color);
        border-radius: $bouton_contour;
        color: $bouton_couleur_texte !important;
        padding: 11px 22px;
        font-weight: 600;
        font-size: 14px;
        bottom: auto;
        top: calc(115px / 2);
        transform: translateY(-50%);
        min-height: 47px;
        right: 100px;

        .dk {
          display: flex;
          color:var(--ui-text-primary-color)
        }

        .mb {
          display: none;
        }
      }

      @media (min-width: 1400px) {
        //right: calc(50vw - 520px);
      }
    }

    .star {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 10px;
      padding: 10px;
      background: transparent;

      svg {
        fill: transparent;
        stroke: $menu_favori_inactif;
      }

      &.active {
        svg {
          fill: $menu_favori_actif;
        }
      }
    }
  }

  .navbar-left {
    position: fixed;
    height: 100vh;
    z-index: 2;
    width: 100%;
    overflow: auto;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    transform: translateX(-100%);
    background: $menu_burger_contour;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    top: 0;

    #loader-container {
      // background: $menu_burger_liens_couleur_fond;
      ~ .swipe .container {
        min-height: calc(100vh - 60px);

        * {
          opacity: 0;
        }
      }
    }

    .navbar-top {
      margin-left: -10px;
      position: relative;

      @media (min-width: 1200px) {
        display: none;
      }
    }

    .search {
      padding: 20px;

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        fill: $menu_burger_icone_valider_recherche;
      }

      input {
        padding-right: 40px;
      }
    }

    a {
      position: relative;
      font-weight: 800;
      color: $menu_burger_liens_couleur_texte;
      font-size: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      align-items: center;
      padding-right: 50px;
      padding-left: 20px;

      > span {
        position: relative;


        .new {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          left: calc(100% + 5px);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: $badge_nouveaute_couleur;
          background: $badge_nouveaute_fond;
          font-size: 12px;
        }
      }

      > svg {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        fill: $menu_burger_liens_couleur_texte;
      }

      .right {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: $menu_burger_liens_couleur_chevrons;
        }
      }
      &:hover{
        background-color: rgba(255,255,255,0.3);
      }
    }

    .item{
      &.selected{
        background-color: rgba(255,255,255,0.3);
       
        
        svg{
          //fill:var(--ui-text-secondary-color);
          
        }
        .right{
          svg{
            //fill:var(--ui-text-secondary-color);
            path{
              //fill:var(--ui-text-secondary-color);
            }
            g{
              //fill:var(--ui-text-secondary-color);
             //fill-rule:initial;
            }
          }
        }
      }
    }



    
    .container {
      overflow: auto;
      background: $menu_background;
      border-bottom-left-radius: $input_radius * 2;
      border-bottom-right-radius: $input_radius * 2;
      position: relative;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(255,255,255,0.8);
       
      }
    
      &::-webkit-scrollbar-track {
        background-color: rgba(255,255,255,0.2);
      };


      .title{
        border-top: 1px solid $menu_burger_liens_couleur_bordures_rubriques;
        margin-bottom: 1rem;
        margin-top: 1rem;
        color: $menu_burger_liens_couleur_rubriques;
        font-weight: bold;
        font-size: 13px;
        padding: 20px 20px 0;
        &.empty {
          padding: 0;
        }
      }
      :nth-child(1) {
        margin-top: none;
        border-top: none;
      }

      .socials {
        padding-top: 50px;
        height: 120px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;

        // position: absolute;
        // bottom: 0;
        // left: 0;
        // width: 100%;
        > :nth-child(1) {
          transform: rotateX(180deg);
        }

        > :nth-child(3) {
          transform: rotate(180deg);
        }

        .svg {
          width: 60px;
          height: 37px;
          overflow: hidden;

          svg {
            width: 60px;
            height: auto;
          }
        }

        &--header {
          width: calc(100% - 120px);
          background: $menu_reseaux_fond_header;
          height: 37px;

          // height: 100%;
          // padding: 5px;
        }

        &--container {
          background: $menu_reseaux_fond_container;
          width: 100%;
          height: calc(100% - 37px);
          padding-left: calc(60px + 5px);
          padding-right: calc(60px + 5px);
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-wrap: wrap;
        }

        &--item {
          padding: 0;
          margin: 5px;
          padding: 5px;
          background: #fff;
          border-radius: 2px;
          height: 34px;
          width: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-25px);

          svg {
            fill: $menu_reseaux_icone_couleur;
            margin-right: 0;
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }

  &.active {
    .navbar-left {
      transform: translateX(0);
      opacity: 1;

      .navbar-top {
        // background: $menu_burger_fond_header !important;
        background: $menu_burger_fond_header;

        .tab {
          .lead {
            background: wheat;
          }

          .title {
            background: linear-gradient(to top, $menu_burger_titre_fond_bas, $menu_burger_titre_fond_haut);

            h1 {
              color: $menu_burger_titre_couleur;
            }
          }

          .svg-right-lead,
          .svg-left-lead {
            position: relative;

            &::after {
              position: absolute;
              top: 0;
              height: 100%;
              left: 0;
              width: 10px;
              background: #002e5a;
              content: '';
            }
          }

          .svg-right,
          .svg-left {
            position: relative;

            &::after {
              position: absolute;
              top: 0;
              height: 100%;
              left: 0;
              width: 10px;
              background: linear-gradient(to top, $menu_burger_titre_fond_bas, $menu_burger_titre_fond_haut);
              content: '';
            }
          }
        }

        .return {
          left: 25px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    background: $menu_desktop_fond;
   

    .navbar-top {
      height: 115px;
      background: $menu_desktop_fond;

      .tab {
        position: absolute;
        top: 100%;
        left: 320px;
        width: calc(100vw - 320px);
        height: 50px;
        justify-content: center;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: $menu_desktop_titre_fond_haut;
          z-index: 2;
        }

        .title {
          width: auto;
          padding: 10px, 30px, 15px, 30px;
        }

        .title-leads {
          width: auto;
          padding: 10px, 30px, 15px, 30px;
        }
      }

      .nav-background {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          height: $menu_desktop_logo_height;
          position: relative;
          z-index: 2;
        }
      }

      .dk-logo {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .left-side-header-app,.right-side-header-app{
          width:320px
        }

        .container-logo-app{
          display: flex;
          align-items: center;
          justify-content: center;
          flex:1;
        }

        &::before,
        &::after {
         // content: '';
          position: absolute;
          top: 0;
          left:calc( 160px + 50%);
          margin: auto;
          border-radius: 50%;
          transform: translateY(-30%) translateX(-50%);
        }

        &::before {
          width: 580px;
          height: 580px;
          background: $desktop_grand_cercle_couleur;

          opacity: $desktop_grand_cercle_opacite;
          z-index: 0;
        }

        &::after {
          z-index: 1;
          opacity: $desktop_petit_cercle_opacite;
          background: transparent;
          width: 380px;
          height: 380px;
          border: 40px solid $desktop_petit_cercle_couleur;
        }

        img {
          height: $menu_desktop_logo_height;
          position: relative;
          z-index: 2;
        }
      }
    }

    .navbar-left {
      opacity: 1;
      transform: none;
      width: 320px;
      height: calc(100vh - 115px);
      top: 115px;
      padding: 0;

      #loader-container {
        background: $menu_burger_liens_couleur_fond;
      }

      .swipe {
        height: 100%;
      }

      .container {
        border-radius: 0;
        height: 100%;
      }

      .search {
        display: none;
      }
    }
  }
}
