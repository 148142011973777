// #devmode {
//   position: fixed;
//   right: 0;
//   width: 100%;
//   background: #000;
//   padding: 10px;
//   z-index: 10000000000;
//   bottom: 0;
//   @media (min-width: 1200px) {
//     width: 300px;
//     top: 0;
//     bottom: auto;
//   }
//   ul {
//     margin: 0;
//   }
//   * {
//     color: #fff;
//   }
// }

#devmode {
  display: none !important;
  * {
    display: none !important;
  }
}
