.fullGride{
  display: grid;
  align-items:center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap:1rem;

  .gridFred{
   span{
    margin-left: 5px;
   }
  }

}





.template-lead {
  @media (min-width: 1200px) {
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-left: 0;
    padding-right: 0;
   
  }
  font-size: 17px!important;
  .gridFrame {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
  }

  .preferences-rappel input[type='checkbox'] {
    // color: red;
    // background-color: aqua !important;
    // font-size: 24px;
  }

  .input {
    .right {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .reset,
      .submit {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        border-radius: 10px;

        svg {
          width: 10px;
          height: 10px;
        }
      }

      .reset {
        background: $profil_information_annuler;
        margin-right: 8px;
      }

      .submit {
        background: $profil_information_valider;
      }

      svg {
        fill: $profil_information_couleur;
        width: 15px;
        height: 15px;
      }
    }
  }

  .quantity-selector {
    >span {
      height: 35px;
      width: calc(100% - 35px);
      font-size: 13px;
      text-align: left;
      justify-content: flex-start;
      padding: 0 12px;
      background: #fff;
    }

    >button {
      height: 35px;
      width: 35px;
    }
  }

  .grid50.prospect,
  .grid50.lead {
    padding: 25px;

    >.line {
      >div {
        padding: 10px 5px;

        >p {
          padding: 0 10px 5px;
        }
      }

      >p {
        margin-bottom: -10px;
        padding: 5px 10px;
      }
    }

    .title {
      border-bottom: 1px solid #707070;
      margin-bottom: 10px;

      p {
        color: #002e5a;
        font-weight: bold;
        padding: 5px 22px;
        font-size: 18px;
      }
    }

    .historique {
      padding: 10px;
    }

    form {

      input,
      textarea {
        background: #fff;
      }
    }

    .line-center {

      p,
      input {
        text-align: center;
      }
    }

    .subtitle {
      padding: 10px 5px;

      p {
        color: #002e5a;
        font-weight: bold;
        padding: 10px 22px;
        font-size: 14px;
        text-transform: uppercase;
        border-radius: $input-radius;
        background: #e2e2e2;
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      padding: 0 5px 10px;

      span {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .line-rappel {
      padding: 10px 5px;

      .rappel {
        background: #e2e2e2;
        border-radius:14px;
        padding: 20px;

        >p {
          font-weight: bold;
          color: #002e5a;
          font-size: 16px;
          padding-bottom: 15px;
          text-transform: uppercase;
        }

        >div {
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
      }
    }

    .line-preferences-rappel {
      padding: 5px 10px;

      .preferences-rappel {
        background: #e2e2e2;
        border-radius: $input-radius;

        >p {
          color: #002e5a;
          font-weight: bold;
          padding: 5px 20px;
          font-size: 14px;
          text-transform: uppercase;
        }

        >div {
          padding: 5px 20px;

          .grid20 {
            display: flex;
            align-items: center;

            span {
              padding-left: 10px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .line-resume {
      padding: 25px 10px;

      .resume {
        background: #002e5a;
        border-radius: $input-radius;
        padding: 20px 40px;

        p,
        .label {
          color: #fff;
        }

        >.line {
          >div {
            padding: 10px 5px;

            >p {
              padding: 0 10px 5px;
            }
          }

          >p {
            margin-bottom: -10px;
            padding: 5px 22px;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          margin-left: -10px;
          margin-right: -10px;
          flex-wrap: wrap;

          button {
            margin: 10px;

            &.btn-alert {
              background: linear-gradient(to bottom, #f55353 17%, #aa2325);
            }

            &.btn-third {
              color: #fff !important;
              text-decoration: underline;
              background: none;
            }
          }
        }
      }
    }

    .qualification {
      padding: 5px 10px;

      .header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;

        span {
          width: 40px;
          text-align: center;

          >p {
            color: #002e5a;
            font-weight: bold;
          }
        }
      }

      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        &:not(:last-child) {
          border-bottom: 1px solid #e2e2e2;
        }

        >span {
          width: calc(100% - 80px);
        }



        >div {
          width: 80px;

          .grid50 {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .actions {
      cursor: pointer;

      &.right {
        display: flex;
        flex-direction: row;
        justify-content: right !important;
      }
    }
  }

  @media (max-width: 1200px) {
    margin-top: 0;

    .header {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 12;
      background: #002e5a;
      border-bottom-left-radius: $input-radius * 4;
      border-bottom-right-radius: $input-radius * 4;
      padding: 10px 1em 0px 1em;
      box-shadow: 0 3px 3px #999999;

      // padding: 40px 50px 20px;
      // margin-top: -25px;
      // margin-left: -20px;
      // margin-right: -20px;
      // margin-bottom: 30px;
      &.hidden {
        display: none;
      }

      .actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        button {
          margin: 5px;
        }
      }

      .btn-back {
        padding-left: 0px !important;
      }

      .line {
        padding: 1em;
      }

      .block {
        padding: 0px 3em 0em 3em;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .line-block {
        padding-bottom: 1em;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      ~.fullGrid {
        >div:not(.grid100) {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          p {
            padding: 0 5px;
          }
        }
      }
    }

    .content {

      padding: 0 1em;
      // background-color: var(--content-bg-color);
      background-color: $prive_cercle;

      .spacer {
        height: 250px;
      }

      .line-content {
        padding: .3em 0 .3em 0;


        .row {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .input {
          padding-bottom: .3em;
        }

        p {
          padding: .5em 0 .5em 0;
          background-color: $prive_cercle;
        }

        .textzone {
          padding-top: .5em;
        }

        .checkbox {
          font-size: .84em;
          font-weight: 400;
        }

        .yes-no {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: end;
          align-items: center;
          padding: .3em 1.7em 0 0;
          font-weight: bold;
        }

        .yes-no-element {
          padding: 0 1.8em 0 0;
        }

      }


    }

    p.label {
      padding: .5em 0 .5em 0;
    }

    form {
      padding: 5px;
    }

    .title {
      color: #002e5a;
      font-weight: bolder;
      text-align: center;
      font-size: 17px;
      position: relative;
      padding-bottom: 5px;
      margin-top: 20px;
      margin-bottom: 10px;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        background: #a0a0a0;
        left: 0;
        right: 0;
        margin: auto;
        width: calc(100% - 60px);
      }
    }

    .line-preferences-rappel {
      padding: 20px 0;

      .preferences-rappel {
        background: #e2e2e2;
        border-radius: $input-radius;
        padding: 25px;

        p {
          color: #002e5a;
          font-weight: bold;
          text-transform: uppercase;
          padding-bottom: 15px;
        }

        .grid50 {
          display: flex;
          align-items: center;
          padding: 5px 0;

          span {
            padding-left: 10px;
          }
        }
      }
    }

    .validation {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 20px;

      button {
        margin: 5px;
        margin: 10px;

        &.btn-alert {
          background: linear-gradient(to bottom, #f55353 17%, #aa2325);
        }

        &.btn-third {
          color: #fff !important;
          text-decoration: underline;
          background: none;
        }
      }
    }

    .statut {
      font-style: italic;
      font-weight: bold;
    }

    input,
    textarea {
      background: #fff;
    }

    .btn-rounded {
      height: 42px;
      width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      min-width: auto;

      svg {
        fill: #fff;
        width: 18px;
        height: 18px;
      }
    }
  }
}